import { ROUTES_ENUM } from '#components/Router';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { LogoSVG } from '#assets/svg';

const MENU_ITEMS: {
  title: string;
  path: ROUTES_ENUM;
}[] = [
  {
    title: 'Главная',
    path: ROUTES_ENUM.MAIN,
  },
  {
    title: 'Смысл игры',
    path: ROUTES_ENUM.MEANING,
  },
  {
    title: 'Правила игры',
    path: ROUTES_ENUM.RULES,
  },
  {
    title: 'База знаний',
    path: ROUTES_ENUM.KNOWLEDGE,
  },
  {
    title: 'Контакты',
    path: ROUTES_ENUM.CONTACTS,
  },
];

export const Header: React.FC = () => {
  return (
    <div className="flex flex-wrap xl:justify-between xl:gap-0 xl:py-[20px] justify-center items-center gap-[30px] pt-[40px]">
      <NavLink to="/">
        <LogoSVG />
      </NavLink>
      <div className="flex flex-wrap xl:justify-start xl:gap-[60px] gap-x-[25px] gap-y-[15px] justify-center">
        {MENU_ITEMS.map(({ title, path }) => (
          <NavLink key={title} to={path}>
            <span className="underline-offset-2 text-lg hover:underline active:text-gray-700">
              {title}
            </span>
          </NavLink>
        ))}
      </div>
      <div className="w-[188px]" />
    </div>
  );
};
