import React, { useContext, useMemo } from 'react';
import { StoryPart } from '#components';
import { GameContext } from '#pages/game';
import { UseGameReturnType } from '#hooks';
import { formatAmount } from '#utils';

export const Part6: React.FC = () => {
  const {
    partIndex,
    answerIndex,
    handleSelectAnswer,
    gotoNext,
    partData: {
      cur: { startAmount, date },
    },
    state: {
      assets: { deposits },
    },
  } = useContext(GameContext) as UseGameReturnType;

  const handleClickButton = () => {
    gotoNext(newState => {
      newState.expenses = newState.expenses + startAmount;
      if (answerIndex === 0) {
        newState.assets.deposits = {
          ...newState.assets.deposits,
          savings: newState.assets.deposits.savings + startAmount,
        };
      }
      if (answerIndex === 2) {
        newState.assets.deposits = {
          ...newState.assets.deposits,
          invest: newState.assets.deposits.invest + startAmount,
        };
      }
      return newState;
    });
  };

  const hasInvest = !!deposits.invest;

  const answers = useMemo(
    () => [
      {
        title:
          'Такие доходности выглядят подозрительно, я понаблюдаю, а деньги пусть полежат на накопительном счете под 5% годовых.',
      },
      {
        title:
          'Конечно, вложу. Где еще можно сколько заработать? Да и друг ерунду предлагать не будет.',
      },
      {
        title: hasInvest
          ? 'У меня открыт ИИС, я себе обещал(а) пополнить его на 150 000 рублей.'
          : 'Положу эти деньги на ИИС.',
      },
    ],
    [hasInvest],
  );

  return (
    <StoryPart
      selectedAnswer={answerIndex}
      partNumber={partIndex + 1}
      partDate={date}
      onClickAnswer={handleSelectAnswer}
      onClickButton={handleClickButton}
      intro={
        <>
          Друг рассказал тебе про новую супервыгодную схему с доходностью до
          600% годовых. Такую возможность заработать давала платформа Кэшбери,
          про нее даже Николай Басков пел в рекламе. Это площадка, которая
          объединяет тех, кто хочет инвестировать деньги и тех, кто хочет взять
          деньги в долг. При этом вкладывать деньги можно в разные инструменты,
          вплоть до криптовалюты. Доходность от 200 до 600%. А если пригласить
          друзей, то и от их доходов можно получать процент.
        </>
      }
      question={
        <>
          У тебя за год накопилось {formatAmount(startAmount, '₽')}.<br /> Как
          поступишь?
        </>
      }
      answers={answers}
    />
  );
};
