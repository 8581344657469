import React, { useContext, useMemo } from 'react';
import { AssetsModal, StoryPart } from '#components';
import { GameContext } from '#pages/game';
import { UseGameReturnType, useAssetsModal } from '#hooks';
import { VIDEO_LINKS } from '#constants';

const MORTRAGE_PAYMENT = 700000;

export const Part8: React.FC = () => {
  const {
    partIndex,
    answerIndex,
    handleSelectAnswer,
    gotoNext,
    assetsTotalRubAmount,
    sellAmount,
    partData: {
      cur: {
        rates,
        date,
        startAmount,
        liabilities: { mortgage },
      },
    },
  } = useContext(GameContext) as UseGameReturnType;

  const {
    modalType,
    selectedAssets,
    remainingBalance,
    setModalType,
    setSelectedAssets,
    setRemainingBalance,
    onModalClose,
    reset,
  } = useAssetsModal({ startAmount });

  const isEnoughToBuy = assetsTotalRubAmount >= MORTRAGE_PAYMENT;

  const handleClickButton = () => {
    const isGetETF =
      (isEnoughToBuy && answerIndex === 1) ||
      (!isEnoughToBuy && answerIndex === 0);

    if (isGetETF && !selectedAssets.length) return;

    gotoNext(state => {
      if (isEnoughToBuy && answerIndex === 0) {
        const newState = sellAmount(state, MORTRAGE_PAYMENT);
        return {
          ...newState,
          liabilities: [
            ...newState.liabilities,
            {
              name: 'mortgage',
              amount: mortgage,
              change: 0,
            },
          ],
        };
      }
      if (isGetETF) {
        const incomePairs = selectedAssets.map(({ price: _, ...rest }) => rest);
        const expenses = startAmount - remainingBalance;
        return {
          ...state,
          cash: state.cash + remainingBalance,
          expenses: state.expenses + expenses,
          assets: {
            ...state.assets,
            pairs: [...state.assets.pairs, ...incomePairs],
          },
        };
      }
      return state;
    });
  };

  const handleClickAnswer = (index: number) => {
    if (isEnoughToBuy && index === 0) {
      handleSelectAnswer(0);
      return;
    }
    if (answerIndex !== index) {
      reset();
      handleSelectAnswer(null);
    }
    setModalType('etf');
  };

  const handleSubmit = () => {
    handleSelectAnswer(isEnoughToBuy ? 1 : 0);
    setModalType(null);
  };

  const handleCloseModal = () => {
    onModalClose();
    handleSelectAnswer(null);
  };

  const answers = useMemo(() => {
    return isEnoughToBuy
      ? [
          {
            title: 'Да! Не зря же откладывал.',
          },
          {
            title:
              'Нет. Пусть деньги работают. Хочу попробовать новый инструмент – ETF. Вложу 50000.',
            link: VIDEO_LINKS.etf,
          },
        ]
      : [
          {
            title:
              'К сожалению, накоплений не хватит. Буду дальше копить. Хочу попробовать новый инструмент – ETF. Вложу 50000.',
            link: VIDEO_LINKS.etf,
          },
        ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <StoryPart
        selectedAnswer={answerIndex}
        partNumber={partIndex + 1}
        partDate={date}
        onClickAnswer={handleClickAnswer}
        onClickButton={handleClickButton}
        intro={
          <>
            Стартовала программа льготной ипотеки со ставкой до 6,5% на
            новостройки! Первоначальный взнос 20%. Это точно знак, что надо
            покупать квартиру. Ты уже даже выбрал(а) себе вариант. Цена 3,5 млн.
            руб. Первоначальный взнос 700 тыс. руб. Cтавка 5% годовых на 20 лет.
          </>
        }
        question={<>Берешь?</>}
        answers={answers}
      />
      <AssetsModal
        remainingBalance={remainingBalance}
        setRemainingBalance={setRemainingBalance}
        selectedAssets={selectedAssets}
        setSelectedAssets={setSelectedAssets}
        assetType={modalType}
        currentRates={rates}
        onRequestClose={handleCloseModal}
        onSubmit={handleSubmit}
      />
    </>
  );
};
