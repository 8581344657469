import { UseGameReturnType } from '#hooks/useGame';
import { GameContext } from '#pages/game';
import { formatAmount } from '#utils';
import React, { useContext } from 'react';

export const CapitalLiabilities: React.FC = () => {
  const { liabilityList, liabilitiesTotalAmount } = useContext(
    GameContext,
  ) as UseGameReturnType;

  if (liabilitiesTotalAmount === 0) return <></>;

  return (
    <div className="flex flex-col gap-[20px] mt-[30px] h-full w-full">
      <h5 className="text-afina-burgundy">ПАССИВЫ:</h5>
      <div className="flex flex-col gap-[22px]">
        {liabilityList.map(({ amount, title }) => (
          <div key={title} className="flex justify-between items-center">
            <span>{title}</span>
            <span>{formatAmount(amount, '₽')}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
