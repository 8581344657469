import React, { ButtonHTMLAttributes, memo } from 'react';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isAnswer?: boolean;
}

export const Button: React.FC<ButtonProps> = memo(
  ({ className, disabled, isAnswer = false, children, ...props }) => {
    const disabledClassName = disabled
      ? 'bg-button-disabled xl:w-[240px] w-full max-w-[400px] p-[20px] rounded-xl font-["Rubik_Medium"] text-white'
      : 'bg-button-default hover:bg-button-hover active:bg-button-active xl:w-[240px] w-full p-[20px] rounded-xl font-["Rubik_Medium"] text-white';
    const enabledClassName = isAnswer
      ? 'bg-answer-default border-2 border-answer-default flex-col gap-[10px] hover:shadow-xl active:bg-answer-active text-black p-[23px] w-full text-center align-middle text-xl rounded-3xl font-["Raleway_Medium"]'
      : 'bg-button-default hover:bg-button-hover active:bg-button-active xl:w-fit xl:min-w-[240px] max-w-[400px] w-full p-[20px] rounded-xl font-["Rubik_Medium"] text-white';
    return (
      <button
        className={`flex justify-center items-center ${
          disabled ? disabledClassName : enabledClassName
        } ${className}`}
        disabled={disabled}
        {...props}
      >
        {children}
      </button>
    );
  },
);
