import { Main, Meaning, Rules, Knowledge, Contacts, Game } from '#pages';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

export enum ROUTES_ENUM {
  MAIN = '/',
  MEANING = '/meaning',
  RULES = '/rules',
  KNOWLEDGE = '/knowledge',
  CONTACTS = '/contacts',
  GAME = '/game',
}

type RouteItem<T> = {
  path: T;
  PageComponent: React.FC;
};

const ROUTES: RouteItem<ROUTES_ENUM>[] = [
  {
    path: ROUTES_ENUM.MAIN,
    PageComponent: Main,
  },
  {
    path: ROUTES_ENUM.MEANING,
    PageComponent: Meaning,
  },
  {
    path: ROUTES_ENUM.RULES,
    PageComponent: Rules,
  },
  {
    path: ROUTES_ENUM.KNOWLEDGE,
    PageComponent: Knowledge,
  },
  {
    path: ROUTES_ENUM.CONTACTS,
    PageComponent: Contacts,
  },
  {
    path: ROUTES_ENUM.GAME,
    PageComponent: Game,
  },
];

export const Router: React.FC = () => {
  return (
    <Routes>
      {ROUTES.map(({ path, PageComponent }) => (
        <Route key={path} path={path} element={<PageComponent />} />
      ))}
    </Routes>
  );
};
