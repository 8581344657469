/** @type {import('tailwindcss').Config} */
import defaultTheme from "tailwindcss/defaultTheme"

const config = {
  content: [
    "./src/**/*.{js,jsx,ts,tsx}",
  ],
  theme: {
    screens: {
      ...defaultTheme.screens,
    },
    extend: {
      colors: {
        afina: {
          purple: '#b359ae',
          green: '#368f69',
          yellow: '#F5AB21',
          burgundy: '#C6212C'
        },
        background: {
          pink: '#E48BDE',
          green: '#9ED3A7',
          game: '#E0BBE6'
        },
        button: {
          default: '#368f69',
          hover: '#4a9a78',
          active: '#30805e',
          disabled: '#86bba5'
        },
        answer: {
          default: '#EED9F1',
          hover: '#f3e4f5',
          active: '#E0B9E4'
        }
      },
      backgroundImage: {
        'gradient-radial': 'radial-gradient(var(--tw-gradient-stops))',
        'answer-with-link-default': 'radial-gradient(ellipse at 50% 100%, #FFFFFF, #EED9F1)',
        'answer-with-link-active': 'radial-gradient(ellipse at 50% 130%, #FFFFFF, #E0B9E4)',
      }
    },
  },
  plugins: [],
 }
 
 export default config