import { ExpandLessSVG, ExpandMoreSVG } from '#assets/svg';
import { CapitalItem } from '#types/CapitalItem';
import { formatAmount } from '#utils/formatAmount';
import React, { useState } from 'react';

interface CapitalExpandableItemProps {
  items: CapitalItem[];
  totalAmount: number;
  caption: string;
}

export const CapitalExpandableItem: React.FC<CapitalExpandableItemProps> = ({
  items,
  totalAmount,
  caption,
}) => {
  const [expand, setExpand] = useState(false);
  if (items.length === 0) return <></>;
  const toggleExpand = () => setExpand(prev => !prev);
  return (
    <div
      className="flex flex-col transition-height text-ellipsis duration-500 overflow-hidden ease-in-out gap-[10px]"
      style={{
        height: expand
          ? (items.length + 1) * 28 + items.length * 10 + 'px'
          : '28px',
      }}
    >
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={() => toggleExpand()}
      >
        <div className="flex items-center gap-[7px]">
          <span>{caption}</span>
          {expand ? (
            <ExpandLessSVG height={24} className="fill-afina-purple" />
          ) : (
            <ExpandMoreSVG height={24} className="fill-afina-purple" />
          )}
        </div>
        <div className="flex items-center gap-[5px]">
          <span>{formatAmount(totalAmount, 'шт.')}</span>
        </div>
      </div>
      {expand
        ? items.map(({ title, suffix, amount }) => (
            <div key={title} className="flex justify-between items-center">
              <div className="flex items-center gap-[15px] min-w-0">
                <span className="text-afina-purple">-</span>
                <span className="truncate w-full" title={title}>
                  {title}
                </span>
              </div>
              <div className="flex gap-[5px] whitespace-nowrap min-w-0">
                <span>{formatAmount(amount, suffix)}</span>
              </div>
            </div>
          ))
        : null}
    </div>
  );
};
