import { Footer, Header, Background } from '#components';
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  SetStateAction,
} from 'react';
import { BackgroundContext, useBackground } from '#hooks/useBackground';
import { useLocation } from 'react-router-dom';
import { ROUTES_ENUM } from '#components/Router';

export const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  const { pathname } = useLocation();
  const { background, setBackground } = useBackground();

  const isNotGame = background === 'transparent';

  const updateBackground = useCallback(
    (newVal: SetStateAction<'filled' | 'transparent'>) => setBackground(newVal),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const backgroundType = useMemo(() => background, [background]);

  useEffect(() => {
    if (pathname !== ROUTES_ENUM.GAME) {
      updateBackground('transparent');
    } else {
      updateBackground('filled');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <Background isNotGame={isNotGame}>
      <BackgroundContext.Provider
        value={{
          background: backgroundType,
          setBackground: updateBackground,
        }}
      >
        {isNotGame && <Header />}
        <div className="main-container flex-grow">{children}</div>
        {isNotGame && <Footer />}
      </BackgroundContext.Provider>
    </Background>
  );
};
