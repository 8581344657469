import {
  AssetData,
  BondsName,
  CurrencyName,
  DepositName,
  EtfName,
  LottedAssetData,
  PairName,
  ProfitName,
  StocksName,
} from '#types';

export const CURRENCIES_DATA: Record<CurrencyName, AssetData> = {
  usd: { title: 'Валюта, $', suffix: '$' },
  btc: { title: 'Криптовалюта, ₿', suffix: '₿' },
};

export const DEPOSITS_DATA: Record<DepositName, AssetData> = {
  invest: { title: 'ИИС', suffix: '₽' },
  savings: { title: 'Накопительный счёт', suffix: '₽' },
  threeYears: { title: 'Вклад 9% на 3 года', suffix: '₽' },
  twoYears: { title: 'Вклад 8% на 2 года', suffix: '₽' },
};

export const STOCKS_DATA: Record<StocksName, LottedAssetData> = {
  sber: { title: 'Акции Сбербанк', suffix: 'шт.', lot: 10 },
  gazp: { title: 'Акции Газпром', suffix: 'шт.', lot: 10 },
  lkoh: { title: 'Акции Лукойл', suffix: 'шт.', lot: 1 },
  gmkn: { title: 'Акции Норильский никель', suffix: 'шт.', lot: 1 },
  alrs: { title: 'Акции АЛРОСА', suffix: 'шт.', lot: 10 },
  plzl: { title: 'Акции Полюс', suffix: 'шт.', lot: 1 },
  yndx: { title: 'Акции Яндекс', suffix: 'шт.', lot: 1 },
  chmf: { title: 'Акции Северсталь', suffix: 'шт.', lot: 1 },
  mgnt: { title: 'Акции Магнит', suffix: 'шт.', lot: 1 },
  jnj: { title: 'Акции Johnson & Johnson', suffix: 'шт.', lot: 1 },
  brkb: { title: 'Акции Berkshire Hathaway', suffix: 'шт.', lot: 1 },
  aapl: { title: 'Акции Apple', suffix: 'шт.', lot: 1 },
  bac: { title: 'Акции Bank of America Corp', suffix: 'шт.', lot: 1 },
  intc: { title: 'Акции Intel', suffix: 'шт.', lot: 1 },
  pm: { title: 'Акции Philip Moris', suffix: 'шт.', lot: 1 },
};

export const BONDS_DATA: Record<BondsName, AssetData> = {
  ofz: { title: 'Облигации ОФЗ 26218', suffix: 'шт.' },
  yarobl: { title: 'Облигации ЯрОбл 2017', suffix: 'шт.' },
  rzd: { title: 'Облигации РЖД-23', suffix: 'шт.' },
};

export const ETFS_DATA: Record<EtfName, AssetData> = {
  fxkz: { title: 'Акции казахстанских компаний', suffix: 'шт.' },
  sbsp: { title: 'Фонд Американские акции', suffix: 'шт.' },
  trur: { title: 'Тинькофф Вечный портфель', suffix: 'шт.' },
};

export const REAL_ESTATE_DATA = {};

export const PROFITS_DATA: Record<ProfitName, AssetData> = {
  bonds: { title: 'Купоны', suffix: '₽' },
  invest: { title: 'Налоговый вычет', suffix: '₽' },
  stocks: { title: 'Дивиденды', suffix: '₽' },
};

export const PAIRS_DATA: Record<PairName, AssetData> = {
  ...CURRENCIES_DATA,
  ...STOCKS_DATA,
  ...BONDS_DATA,
  ...ETFS_DATA,
};

export const ASSETS_DATA = {
  currencies: CURRENCIES_DATA,
  stocks: STOCKS_DATA,
  bonds: BONDS_DATA,
  etf: ETFS_DATA,
  profits: PROFITS_DATA,
  pairs: PAIRS_DATA,
};
