import { useEffect, useMemo, useState } from 'react';
import {
  DEPOSITS_DATA,
  DEPOSIT_RATES,
  INITIAL_ASSETS,
  PAIRS_DATA,
  PROFITS_DATA,
  STORY_DATA,
  PROPERTIES_DATA,
  LIABILITIES_DATA,
} from '#constants';
import { GameState, DepositName, ProfitName, Pair } from '#types';
import {
  calcChange,
  calcTaxDeduction,
  cloneState,
  getPairType,
  isBondsName,
  isStocksName,
} from '#utils';
import { useTabActive } from './useTabActive';
import {
  INITIAL_BASE_INFO,
  INITIAL_MISC_INFO,
  INTIAL_PAIRS_INFO,
} from '#constants/initialData';

const GAME_TIME = 1000 * 60 * 2;

export const useGame = () => {
  /*
   * Game state
   */

  const [answerIndex, setAnswerIndex] = useState<number | null>(null);
  const [state, setState] = useState<GameState>({
    partIndex: 0,
    cash: 0,
    assetsInflated: 0,
    expenses: 0,
    answered: [],
    assets: INITIAL_ASSETS,
    liabilities: [],
  });

  /*
   * Timer logic
   */

  const [count, setCount] = useState<number>(GAME_TIME);
  const [showTimeEnd, setShowTimeEnd] = useState(false);

  const isTabActive = useTabActive();

  useEffect(() => {
    if (count > 0) {
      if (!isTabActive) return () => {};
      const timeout = setTimeout(() => setCount(prev => prev - 1000), 1000);
      return () => clearInterval(timeout);
    }
    setShowTimeEnd(true);
    return () => {};
  }, [count, isTabActive]);

  useEffect(() => {
    setCount(GAME_TIME);
  }, [state.partIndex]);

  /*
   * Calculated Info
   */

  const partData = useMemo(
    () => ({
      prev: STORY_DATA[state.partIndex - 1],
      cur: STORY_DATA[state.partIndex],
      next: STORY_DATA[state.partIndex + 1],
    }),
    [state.partIndex],
  );

  const lastAnswer = useMemo(
    () => state.answered[state.answered.length - 1],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.answered.length],
  );

  const cashInfo = useMemo(
    () => ({
      title: 'Наличные, руб.',
      amount: state.cash,
      change: 0,
      suffix: '₽',
    }),
    [state.cash],
  );

  const miscInfo = useMemo(
    () =>
      state.liabilities.reduce((acc, { name, amount, change }) => {
        if (amount) {
          return {
            ...acc,
            liabilities: {
              list: [
                ...acc.liabilities.list,
                {
                  change,
                  title: LIABILITIES_DATA[name].title,
                  amount: partData.cur.liabilities[name],
                  suffix: LIABILITIES_DATA[name].suffix,
                },
              ],
              totalAmount:
                acc.liabilities.totalAmount + partData.cur.liabilities[name],
            },
            properties: {
              list: [
                ...acc.properties.list,
                {
                  change,
                  title: PROPERTIES_DATA[name].title,
                  amount: partData.cur.properties[name],
                  suffix: PROPERTIES_DATA[name].suffix,
                },
              ],
              totalAmount:
                acc.properties.totalAmount + partData.cur.properties[name],
            },
          };
        }
        return acc;
      }, INITIAL_MISC_INFO),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.liabilities],
  );

  const profitsInfo = useMemo(
    () =>
      (Object.keys(state.assets.profits) as ProfitName[]).reduce(
        (acc, name) => {
          const amount = state.assets.profits[name];
          if (amount) {
            return {
              list: [
                ...acc.list,
                {
                  amount,
                  change: 0,
                  title: PROFITS_DATA[name].title,
                  suffix: PROFITS_DATA[name].suffix,
                },
              ],
              totalAmount: acc.totalAmount + amount,
            };
          }
          return acc;
        },
        INITIAL_BASE_INFO,
      ),
    [state.assets.profits],
  );

  const depositsInfo = useMemo(
    () =>
      (Object.keys(state.assets.deposits) as DepositName[]).reduce(
        (acc, name) => {
          const amount = state.assets.deposits[name];
          if (amount) {
            return {
              list: [
                ...acc.list,
                {
                  amount,
                  change: 0,
                  title: DEPOSITS_DATA[name].title,
                  suffix: DEPOSITS_DATA[name].suffix,
                },
              ],
              totalAmount: acc.totalAmount + amount,
            };
          }
          return acc;
        },
        INITIAL_BASE_INFO,
      ),
    [state.assets.deposits],
  );

  const pairsInfo = useMemo(
    () =>
      state.assets.pairs.reduce((acc, { name, amount, change }) => {
        const pairType = getPairType(name);
        const rubAmount = amount * partData.cur.rates[name];
        return {
          ...acc,
          [pairType]: {
            list: [
              ...acc[pairType].list,
              {
                change,
                amount,
                rubAmount,
                title: PAIRS_DATA[name].title,
                suffix: PAIRS_DATA[name].suffix,
              },
            ],
            totalAmount: acc[pairType].totalAmount + amount,
            totalRubAmount: acc[pairType].totalRubAmount + rubAmount,
          },
          totalAmount: acc.totalAmount + rubAmount,
          totalRubAmount: acc.totalRubAmount + rubAmount,
        };
      }, INTIAL_PAIRS_INFO),
    [partData.cur.rates, state.assets.pairs],
  );

  const restAssetsList = useMemo(
    () => [
      ...(cashInfo.amount ? [cashInfo] : []),
      ...pairsInfo.currency.list.map(i => ({ ...i, amount: i.rubAmount ?? i.amount })),
      ...profitsInfo.list,
      ...depositsInfo.list,
      ...miscInfo.properties.list,
    ],
    [
      cashInfo,
      depositsInfo.list,
      miscInfo.properties.list,
      pairsInfo.currency.list,
      profitsInfo.list,
    ],
  );

  const assetsTotalRubAmount = useMemo(
    () =>
      cashInfo.amount +
      miscInfo.properties.totalAmount +
      depositsInfo.totalAmount +
      profitsInfo.totalAmount +
      pairsInfo.totalRubAmount,
    [
      cashInfo.amount,
      depositsInfo.totalAmount,
      pairsInfo.totalRubAmount,
      profitsInfo.totalAmount,
      miscInfo.properties.totalAmount,
    ],
  );

  const totalProfitability = useMemo(
    () =>
      assetsTotalRubAmount
        ? ((assetsTotalRubAmount - state.expenses) / assetsTotalRubAmount) * 100
        : 0,
    [state.expenses, assetsTotalRubAmount],
  );

  /*
   * Methods
   */

  const gotoNext = (
    answersLogic: (mutableState: GameState) => GameState = mutableState =>
      mutableState,
  ) => {
    setState(prev => {
      const currentAnswer = answerIndex;
      setAnswerIndex(null);

      const newState = answersLogic(cloneState(prev));

      if (currentAnswer === null) {
        newState.cash += partData.cur.startAmount;
        newState.expenses += partData.cur.startAmount;
      }

      if (prev.assets.deposits.invest < newState.assets.deposits.invest) {
        newState.assets.profits.invest += calcTaxDeduction(
          newState.assets.deposits.invest - prev.assets.deposits.invest,
        );
      }

      const newPairs = newState.assets.pairs.map(pair => {
        const curRate = partData.cur.rates[pair.name];
        const nextRate = partData.next.rates[pair.name];

        if (isBondsName(pair.name)) {
          newState.assets.profits.bonds +=
            pair.amount * partData.cur.profits[pair.name];
        }

        if (isStocksName(pair.name)) {
          newState.assets.profits.stocks +=
            pair.amount * partData.cur.profits[pair.name];
        }

        return {
          ...pair,
          change: calcChange(curRate, nextRate),
        };
      });

      const newDeposits = (
        Object.keys(newState.assets.deposits) as DepositName[]
      ).reduce(
        (acc, key) => ({
          ...acc,
          [key]: newState.assets.deposits[key] * DEPOSIT_RATES[key],
        }),
        { ...INITIAL_ASSETS.deposits },
      );

      const newLiabibilities = newState.liabilities.map(
        ({ name, amount: prevAmount }) => {
          const newAmount = partData.next.liabilities[name];
          newState.expenses = newState.expenses + prevAmount - newAmount;
          return {
            name,
            amount: newAmount,
            change: calcChange(prevAmount, newAmount),
          };
        },
      );

      (newState.assetsInflated +=
        assetsTotalRubAmount * partData.cur.inflation),
        setShowTimeEnd(false);

      return {
        ...newState,
        answered: [...newState.answered, currentAnswer],
        assets: {
          pairs: newPairs,
          deposits: newDeposits,
          profits: {
            ...newState.assets.profits,
          },
        },
        liabilities: newLiabibilities,
        currentAnswer: null,
        partIndex: newState.partIndex + 1,
      };
    });
  };

  const sellAmount = (mutableState: GameState, amount: number) => {
    let amountLeft = amount;

    if (mutableState.cash >= amountLeft) {
      mutableState.cash -= amountLeft;
      return {
        ...mutableState,
        cash: mutableState.cash - amountLeft,
      };
    }

    amountLeft -= mutableState.cash;
    mutableState.cash = 0;

    const { ...newProfits } = mutableState.assets.profits;
    let profitName: ProfitName;

    for (profitName in newProfits) {
      if (newProfits[profitName] === 0) continue;
      const diff = newProfits[profitName] - amountLeft;
      if (diff < 0) {
        amountLeft -= newProfits[profitName];
        newProfits[profitName] = 0;
        continue;
      }

      amountLeft = 0;
      newProfits[profitName] = diff;

      return {
        ...mutableState,
        assets: {
          ...mutableState.assets,
          profits: newProfits,
        },
      };
    }

    const { ...newDeposits } = mutableState.assets.deposits;
    let depositName: DepositName;

    for (depositName in newDeposits) {
      if (newDeposits[depositName] === 0) continue;
      const diff = newDeposits[depositName] - amountLeft;
      if (diff < 0) {
        amountLeft = amountLeft - newDeposits[depositName];
        newDeposits[depositName] = 0;
        continue;
      }

      amountLeft = 0;
      newDeposits[depositName] = diff;

      return {
        ...mutableState,
        assets: {
          ...mutableState.assets,
          profits: newProfits,
          deposits: newDeposits,
        },
      };
    }

    const { pairs: iterablePairs } = mutableState.assets;
    const newPairs: Pair[] = [];

    for (let i = 0; i < iterablePairs.length; i++) {
      if (amountLeft === 0) {
        newPairs.push({ ...iterablePairs[i] });
        continue;
      }
      const rubAmount =
        iterablePairs[i].amount * partData.cur.rates[iterablePairs[i].name];
      const diff = rubAmount - amountLeft;
      if (diff < 0) {
        amountLeft -= rubAmount;
        continue;
      }
      amountLeft = 0;

      newPairs.push({
        ...iterablePairs[i],
        amount:
          iterablePairs[i].amount -
          diff / partData.cur.rates[iterablePairs[i].name],
      });
    }

    return {
      ...mutableState,
      assets: {
        pairs: newPairs,
        profits: newProfits,
        deposits: newDeposits,
      },
    };
  };

  const handleSelectAnswer = (index: typeof answerIndex) => {
    setAnswerIndex(index);
  };

  return {
    count,
    showTimeEnd,

    state,
    partIndex: state.partIndex,
    answerIndex,
    lastAnswer,
    partData,

    totalProfitability,
    assetsTotalRubAmount,

    liabilitiesTotalAmount: miscInfo.liabilities.totalAmount,
    stocksTotalAmount: pairsInfo.stocks.totalAmount,
    bondsTotalAmount: pairsInfo.bonds.totalAmount,
    etfTotalAmount: pairsInfo.etf.totalAmount,
    
    bondsTotalRubAmount: pairsInfo.bonds.totalRubAmount,
    etfTotalRubAmount: pairsInfo.etf.totalRubAmount,
    stocksTotalRubAmount: pairsInfo.stocks.totalRubAmount,

    liabilityList: miscInfo.liabilities.list,
    stockList: pairsInfo.stocks.list,
    bondsList: pairsInfo.bonds.list,
    etfList: pairsInfo.etf.list,
    restAssetsList,

    handleSelectAnswer,
    sellAmount,
    gotoNext,
  } as const;
};

export type UseGameReturnType = ReturnType<typeof useGame>;
