import React, { PropsWithChildren } from 'react';

export const TextContentWrapper: React.FC<PropsWithChildren> = ({
  children,
}) => {
  return (
    <div className="flex flex-col justify-center xl:items-start items-center gap-[30px] w-full h-full xl:py-[50px] py-[10px]">
      {children}
    </div>
  );
};
