import { createContext, useState } from 'react';

export const useBackground = () => {
  const [background, setBackground] = useState<'filled' | 'transparent'>(
    'filled',
  );

  return {
    background,
    setBackground,
  };
};

export type BackgroundContextReturnType = ReturnType<typeof useBackground>;

export const BackgroundContext =
  createContext<BackgroundContextReturnType | null>(null);
