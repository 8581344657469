import React, { useContext } from 'react';
import { StoryPart } from '#components';
import { GameContext } from '#pages/game';
import { UseGameReturnType } from '#hooks';
import { VIDEO_LINKS } from '#constants';

export const Part9: React.FC = () => {
  const {
    partIndex,
    answerIndex,
    handleSelectAnswer,
    gotoNext,
    partData: {
      cur: { date },
    },
  } = useContext(GameContext) as UseGameReturnType;

  const handleClickButton = () => {
    gotoNext(state => {
      if (answerIndex === 0) {
        return {
          ...state,
          liabilities: [
            ...state.liabilities,
            {
              name: 'car',
              amount: 600000,
              change: 0,
            },
          ],
        };
      }
      if (answerIndex === 1) {
        return {
          ...state,
          liabilities: [
            ...state.liabilities,
            {
              name: 'personal',
              amount: 500000,
              change: 0,
            },
          ],
        };
      }
      return state;
    });
  };

  return (
    <StoryPart
      selectedAnswer={answerIndex}
      partNumber={partIndex + 1}
      partDate={date}
      onClickAnswer={handleSelectAnswer}
      onClickButton={handleClickButton}
      intro={
        <>
          В твоей жизни случилось важное событие! Свадьба, а затем и рождение
          ребенка.
          <br /> Начались бесконечные поездки к врачам, в бассейн, к родителям.
          <br />
          Вы понимаете, что семье нужна машина.
        </>
      }
      question={
        <>
          Без кредита не обойтись.
          <br /> Не хватает 500 000 рублей.
          <br /> Осталось только определиться, какой выбрать.
        </>
      }
      answers={[
        {
          title:
            'Автокредит на 600 000 рублей (500 000 руб. на машину, 50 000 КАСКО, 50 000 страхование жизни) под 5% годовых на 5 лет с ежемесячным платежом 11 300 рублей.',
          link: VIDEO_LINKS.credit,
        },
        {
          title:
            'Потребительский кредит на 500 000 рублей под 9% годовых на 5 лет с ежемесячным платежом 10 400 рублей.',
          link: VIDEO_LINKS.credit,
        },
      ]}
    />
  );
};
