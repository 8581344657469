import React, { memo } from 'react';
import { UseGameReturnType } from '#hooks';
import { Story } from '#components';

interface CurrentPartProps {
  partIndex: UseGameReturnType['state']['partIndex'];
}

export const CurrentPart: React.FC<CurrentPartProps> = memo(({ partIndex }) => {
  switch (partIndex) {
    case 0:
      return <Story.Part1 />;
    case 1:
      return <Story.Part2 />;
    case 2:
      return <Story.Part3 />;
    case 3:
      return <Story.Part4 />;
    case 4:
      return <Story.Part5 />;
    case 5:
      return <Story.Part6 />;
    case 6:
      return <Story.Part7 />;
    case 7:
      return <Story.Part8 />;
    case 8:
      return <Story.Part9 />;
    case 9:
      return <Story.Part10 />;
    case 10:
      return <Story.Part11 />;
    default:
      return <></>;
  }
});
