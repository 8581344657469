import React, { useMemo } from 'react';
import { MayHaveLabel, ResponsivePie } from '@nivo/pie';
import { takeModulo } from '#utils';
import { useBreakpoint } from '#hooks/useBreakpoint';

type TotalChartProps = {
  totalData: MayHaveLabel[];
  assetsTotal: string | number;
  liabilitiesTotal: string | number;
  className?: string;
};

const TOTAL_COLORS = ['#d7636b', '#F7C473'];

export const TotalChart: React.FC<TotalChartProps> = ({
  totalData,
  assetsTotal,
  liabilitiesTotal,
  className = '',
}) => {
  const pieData = useMemo(
    () =>
      totalData.map((item, index) => ({
        ...item,
        color: TOTAL_COLORS[takeModulo(index + 1, TOTAL_COLORS.length)],
      })),

    [totalData],
  );

  const isXl = useBreakpoint('xl');

  const style = {
    width: isXl ? '1000px' : document.body.clientWidth - 40 + 'px',
    height: isXl ? '500px' : document.body.clientWidth - 100 + 'px',
  };

  const margin = {
    top: isXl ? 70 : 10,
    right: 0,
    bottom: isXl ? 70 : 10,
    left: 0,
  };

  const theme = {
    labels: {
      text: {
        fontSize: isXl ? '1.125rem' : '4vw',
        fontFamily: 'Raleway Medium',
      },
    },
  };

  return (
    <div className={`relative flex ${className}`} style={style}>
      <ResponsivePie
        theme={theme}
        arcLabel={datum => `${datum.value}%`}
        colors={datum => datum.data.color}
        arcLinkLabelsColor={datum => datum.data.color}
        data={pieData}
        margin={margin}
        innerRadius={0.67}
        padAngle={0.7}
        cornerRadius={0}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
        enableArcLinkLabels={isXl}
        arcLinkLabelsSkipAngle={0}
        arcLinkLabelsStraightLength={20}
        arcLinkLabelsDiagonalLength={50}
        arcLinkLabelsTextColor="black"
        arcLinkLabelsThickness={1}
        arcLabelsSkipAngle={5}
        arcLabelsTextColor="white"
        tooltip={() => <></>}
      />
      <div
        className={`absolute font-['Rubik_Medium'] ${
          isXl ? 'text-xl' : 'text-[4.5vw] leading-[6vw]'
        } top-0 bottom-0 left-0 right-0 m-auto flex flex-col gap-[10px] items-center justify-center text-center pointer-events-none uppercase`}
      >
        <div className="flex flex-col text-afina-yellow">
          <span>Активы</span>
          <span>{assetsTotal}</span>
        </div>
        <div className="flex flex-col text-afina-burgundy">
          <span>Пассивы</span>
          <span>{liabilitiesTotal}</span>
        </div>
      </div>
    </div>
  );
};
