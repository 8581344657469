import React, { useContext } from 'react';
import { formatAmount } from '#utils';
import { GameContext } from '#pages/game';
import { UseGameReturnType } from '#hooks/useGame';
import { CapitalExpandableItem } from '..';

export const CapitalAssets: React.FC = () => {
  const {
    restAssetsList,
    stockList,
    stocksTotalAmount,
    bondsList,
    bondsTotalAmount,
    etfList,
    etfTotalAmount,
    assetsTotalRubAmount,
  } = useContext(GameContext) as UseGameReturnType;
  if (assetsTotalRubAmount === 0) return <></>;
  return (
    <div className="flex flex-col gap-[20px] mt-[30px] h-full w-full">
      <h5 className="text-afina-yellow">АКТИВЫ:</h5>
      <div className="flex flex-col gap-[22px]">
        {restAssetsList.map(({ title, suffix, amount }) => (
          <div key={title} className="flex justify-between items-center">
            <span>{title}</span>
            <div className="flex gap-[5px]">
              <span>{formatAmount(amount, suffix)}</span>
            </div>
          </div>
        ))}
        <CapitalExpandableItem
          items={stockList}
          totalAmount={stocksTotalAmount}
          caption="Акции"
        />
        <CapitalExpandableItem
          items={bondsList}
          totalAmount={bondsTotalAmount}
          caption="Облигации"
        />
        <CapitalExpandableItem
          items={etfList}
          totalAmount={etfTotalAmount}
          caption="ETF"
        />
      </div>
    </div>
  );
};
