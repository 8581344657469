export const VIDEO_LIST: { title: string; videoId: string }[] = [
  {
    title: 'Вклад',
    videoId: 'K0Y_Ymb7680',
  },
  {
    title: 'Накопительный счет',
    videoId: 'e3DhHou2spM',
  },
  {
    title: 'Кредит',
    videoId: 'VxCgVQ0ohEc',
  },
  {
    title: 'Риск-Доходность',
    videoId: 'ReI3R5PGF9w',
  },
  {
    title: 'ИИС',
    videoId: 'pWScEcFd5iY',
  },
  {
    title: 'Облигации',
    videoId: '7h_IOUQHHJA',
  },
  {
    title: 'Акции',
    videoId: 'DLHhdo9CeOg',
  },
  {
    title: 'ETF',
    videoId: 'wO9Ipo-b1CU',
  },
  {
    title: 'Недвижимость',
    videoId: '0fr0r3IEUZ0',
  },
  {
    title: 'Криптовалюта',
    videoId: 'w7amLqKigVw',
  },
];

export const VIDEO_LINKS = {
  deposit: {
    title: VIDEO_LIST[0].title,
    to: 'https://youtube.com/watch?v=' + VIDEO_LIST[0].videoId,
  },
  savings: {
    title: VIDEO_LIST[1].title,
    to: 'https://youtube.com/watch?v=' + VIDEO_LIST[1].videoId,
  },
  credit: {
    title: VIDEO_LIST[2].title,
    to: 'https://youtube.com/watch?v=' + VIDEO_LIST[2].videoId,
  },
  risk: {
    title: VIDEO_LIST[3].title,
    to: 'https://youtube.com/watch?v=' + VIDEO_LIST[3].videoId,
  },
  iis: {
    title: VIDEO_LIST[4].title,
    to: 'https://youtube.com/watch?v=' + VIDEO_LIST[4].videoId,
  },
  bonds: {
    title: VIDEO_LIST[5].title,
    to: 'https://youtube.com/watch?v=' + VIDEO_LIST[5].videoId,
  },
  stocks: {
    title: VIDEO_LIST[6].title,
    to: 'https://youtube.com/watch?v=' + VIDEO_LIST[6].videoId,
  },
  etf: {
    title: VIDEO_LIST[7].title,
    to: 'https://youtube.com/watch?v=' + VIDEO_LIST[7].videoId,
  },
  realEstate: {
    title: VIDEO_LIST[8].title,
    to: 'https://youtube.com/watch?v=' + VIDEO_LIST[8].videoId,
  },
  crypto: {
    title: VIDEO_LIST[9].title,
    to: 'https://youtube.com/watch?v=' + VIDEO_LIST[9].videoId,
  },
};
