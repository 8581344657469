import React from 'react';
import { Button, TextContentWrapper } from '#components';
import { useNavigate } from 'react-router-dom';
import { ROUTES_ENUM } from '#components/Router';

export const Rules: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(ROUTES_ENUM.GAME);
  };
  return (
    <TextContentWrapper>
      <h1>Правила игры</h1>
      <p className="xl:w-[65%] text-justify">
        В игре у вас будет 10 лет на то, чтобы сформировать свой капитал.
        <br />
        <br />
        1 игровой год длится 2 минуты в реальной жизни.
        <br />
        <br />
        За это время вам необходимо будет внимательно прочитать описание той
        ситуации, в которую вы попали, и сделать выбор, как использовать
        средства, которые у вас будут по условиям.
        <br />
        <br />В игре, как и в жизни, нельзя победить или проиграть. Вы либо
        ничего не накопите, либо у вас появятся какие-то активы.
        <br />
        <br />
        Все ваши активы будут отображаться в окне Капитал, там же можно будет
        увидеть доходность ваших вложений (если они будут).
        <br />
        <br />
        В ходе игры вам будут предложены разные инструменты для вложения
        средств: накопительный счёт, банковский вклад, облигации, акции, etf и
        т.д. Подробнее про инструменты вы можете узнать из уроков, которые будут
        показаны в ходе игры, или в разделе База знаний.
        <br />
        <br />В разделе Контакты вас ждут ссылки на аккаунты Школы финансовой
        грамотности Афина в социальных сетях, где можно регулярно получать
        полезную информацию о финансах и инвестициях. Также вы сможете связаться
        с автором проекта, чтобы задать вопросы и поработать лично над вашей
        ситуацией.
      </p>
      <Button className='xl:mt-0 mt-[15px]' onClick={handleClick}>Начать игру</Button>
    </TextContentWrapper>
  );
};
