import React, { useContext } from 'react';
import { StoryPart } from '#components';
import { GameContext } from '#pages/game';
import { UseGameReturnType } from '#hooks';
import { formatAmount } from '#utils';
import { VIDEO_LINKS } from '#constants';

export const Part5: React.FC = () => {
  const {
    partIndex,
    answerIndex,
    handleSelectAnswer,
    gotoNext,
    partData: {
      cur: {
        startAmount,
        date,
        rates: { btc: btcRate },
      },
    },
  } = useContext(GameContext) as UseGameReturnType;

  const handleClickButton = () => {
    gotoNext(newState => {
      newState.expenses += startAmount;
      if (answerIndex === 0) {
        newState.assets.pairs = [
          ...newState.assets.pairs,
          {
            name: 'btc',
            amount: startAmount / btcRate,
            change: 0,
          },
        ];
      }
      if (answerIndex === 1) {
        newState.assets.deposits = {
          ...newState.assets.deposits,
          savings: newState.assets.deposits.savings + startAmount,
        };
      }
      if (answerIndex === 2) {
        newState.assets.deposits = {
          ...newState.assets.deposits,
          invest: newState.assets.deposits.invest + startAmount,
        };
      }
      return newState;
    });
  };

  return (
    <StoryPart
      selectedAnswer={answerIndex}
      partNumber={partIndex + 1}
      partDate={date}
      onClickAnswer={handleSelectAnswer}
      onClickButton={handleClickButton}
      intro={
        <>
          Все вокруг помешались на криптовалюте . Сегодня курс биткоина побил
          рекорд и составил 1100 долларов.
          <br /> Есть все предпосылки, что курс будет расти и дальше, несмотря
          на то, что в мире нет законов, которые бы легализовали эту валюту. Она
          полностью неуправляемая.
        </>
      }
      question={
        <>
          Ты понимаешь, что вкладываться в криптовалюту рискованно,
          <br /> но, с другой стороны, когда, как не в молодости, совершать
          ошибки?
          <br /> Что выбираешь?
        </>
      }
      answers={[
        {
          title: `Куплю биткоин на отложенные ${formatAmount(
            startAmount,
          )} рублей.`,
          link: VIDEO_LINKS.crypto,
        },
        {
          title: `Оставлю ${formatAmount(
            startAmount,
          )} лежать на накопительном счете под 5% годовых`,
          link: VIDEO_LINKS.savings,
        },
        {
          title: `У меня открыт ИИС, я себе обещал(а) пополнить его на ${startAmount} рублей.`,
        },
      ]}
    />
  );
};
