import {
  Assets,
  BondsName,
  CapitalItem,
  LiabilityName,
  PairName,
  PairType,
  StocksName,
} from '#types';

export const INITIAL_ASSETS: Assets = {
  deposits: {
    threeYears: 0,
    twoYears: 0,
    invest: 0,
    savings: 0,
  },
  pairs: [],
  profits: {
    bonds: 0,
    invest: 0,
    stocks: 0,
  },
};

export const INITIAL_PROFITS: Record<StocksName | BondsName, number> = {
  sber: 0,
  gazp: 0,
  lkoh: 0,
  gmkn: 0,
  alrs: 0,
  plzl: 0,
  yndx: 0,
  chmf: 0,
  mgnt: 0,
  jnj: 0,
  brkb: 0,
  aapl: 0,
  bac: 0,
  intc: 0,
  pm: 0,
  ofz: 0,
  yarobl: 0,
  rzd: 0,
};

export const INITIAL_RATES: Record<PairName, number> = {
  usd: 0,
  btc: 0,
  sber: 0,
  gazp: 0,
  lkoh: 0,
  gmkn: 0,
  alrs: 0,
  plzl: 0,
  yndx: 0,
  chmf: 0,
  mgnt: 0,
  jnj: 0,
  brkb: 0,
  aapl: 0,
  bac: 0,
  intc: 0,
  pm: 0,
  fxkz: 0,
  sbsp: 0,
  trur: 0,
  ofz: 0,
  yarobl: 0,
  rzd: 0,
};

export const INITIAL_LIABILITIES: Record<LiabilityName, number> = {
  car: 0,
  mortgage: 0,
  personal: 0,
};

export const INITIAL_PROPERTIES: Record<LiabilityName, number> = {
  car: 500000,
  mortgage: 3500000,
  personal: 500000,
};

export const INITIAL_BASE_INFO: {
  list: CapitalItem[];
  totalAmount: number;
} = {
  list: [],
  totalAmount: 0,
};

export const INTIAL_PAIRS_INFO: {
  [key in PairType]: {
    totalRubAmount: number;
  } & typeof INITIAL_BASE_INFO;
} & {
  totalAmount: number;
  totalRubAmount: number;
} = {
  currency: {
    ...INITIAL_BASE_INFO,
    totalRubAmount: 0,
  },
  stocks: {
    ...INITIAL_BASE_INFO,
    totalRubAmount: 0,
  },
  bonds: {
    ...INITIAL_BASE_INFO,
    totalRubAmount: 0,
  },
  etf: { ...INITIAL_BASE_INFO, totalRubAmount: 0 },
  totalAmount: 0,
  totalRubAmount: 0,
};

export const INITIAL_MISC_INFO: {
  [key in 'liabilities' | 'properties']: typeof INITIAL_BASE_INFO;
} = {
  liabilities: {
    ...INITIAL_BASE_INFO,
  },
  properties: {
    ...INITIAL_BASE_INFO,
  },
};
