import React from 'react';
import { Button, TextContentWrapper } from '#components';
import { useNavigate } from 'react-router-dom';
import { ROUTES_ENUM } from '#components/Router';

export const Meaning: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(ROUTES_ENUM.GAME);
  };
  return (
    <TextContentWrapper>
      <h1>Смысл игры</h1>
      <p className="xl:w-[65%] text-justify">
        Перед вами игра, которая научит вас создавать и преумножать свой
        капитал.
        <br />
        <br /> Игра начнется в ваши 18 лет и продлится в течение еще десяти. За
        это время вы освоите разнообразные инструменты накопления и
        инвестирования, сможете опробовать их на своих деньгах. Это симулятор
        управления личными финансами, который призван показать, что заниматься
        своими финансами стоит начать как можно раньше.
        <br />
        <br />
        Зачем? Давайте посмотрим цифры:
        <br />
        <br />
        Если бы в течение 5 лет обучения в университете (2017-2022) вы не
        съедали по 1 сникерсу в неделю, а вместо этого покупали бы по 1 доллару,
        то сейчас у вас было бы 232 доллара (примерно 19 тыс. руб.).
        <br />
        <br />
        Если бы вы 10 лет назад купили 1 акцию ВСМПО-АВИСМА за 5700 рублей (а не
        брендовые кроссовки, которые проносили 1 сезон), то сейчас могли бы
        продать ее за 52 000 рублей.
        <br />
        <br />
        Если бы вы в течение 10 лет ежемесячно откладывали по 4500 рублей под
        12% годовых, то у вас уже был бы 1 миллион?
        <br />
        <br />А знаете ли вы, сколько можно накопить через 10 лет, если вложить
        свои деньги в несколько разных активов, вместо того, чтобы тратить их на
        что-то не сильно нужное? Можно получить очень много, если подходить к
        накоплению с умом и разбираться в тех инструментах, которые планируете
        использовать.
        <br />
        <br />В вопросе денег время может работать на вас. Большие деньги
        требуют больше времени, поэтому стоит начать свой путь к ним именно
        сейчас. Игра вам в этом поможет.
      </p>
      <Button className='xl:mt-0 mt-[15px]' onClick={handleClick}>Начать игру</Button>
    </TextContentWrapper>
  );
};
