import React, { useContext, useMemo } from 'react';
import { StoryPart } from '#components';
import { GameContext } from '#pages/game';
import { UseGameReturnType } from '#hooks';
import { calcChange, formatAmount } from '#utils';

export const Part3: React.FC = () => {
  const {
    partIndex,
    answerIndex,
    lastAnswer,
    handleSelectAnswer,
    gotoNext,
    partData: {
      cur: {
        startAmount,
        date,
        rates: { usd: usdRate },
      },
      prev: {
        rates: { usd: prevUsdRate },
      },
    },
  } = useContext(GameContext) as UseGameReturnType;

  const handleClickButton = () => {
    gotoNext(newState => {
      if (lastAnswer === 0) {
        if (answerIndex === 0) {
          newState.expenses = newState.expenses + startAmount;
          newState.assets.pairs = [
            ...newState.assets.pairs,
            {
              name: 'usd',
              amount: Math.floor(startAmount / usdRate),
              change: calcChange(0, usdRate),
            },
          ];
        } else {
          newState.cash = newState.cash + startAmount;
          newState.expenses = newState.expenses + startAmount;
        }
      } else {
        if (answerIndex === 0) {
          const usd = newState.assets.pairs.find(p => p.name === 'usd');
          if (usd) {
            newState.cash = newState.cash + usd.amount * usdRate;
            newState.assets.pairs = newState.assets.pairs.filter(
              p => p.name !== 'usd',
            );
          }
        }
        if (answerIndex === 1) {
          newState.expenses = newState.expenses + startAmount;
          const usdAmount = startAmount / usdRate;
          const usd = newState.assets.pairs.find(p => p.name === 'usd');
          if (usd) {
            newState.assets.pairs = [
              ...newState.assets.pairs.filter(p => p.name !== 'usd'),
              {
                name: 'usd',
                amount: usd.amount + usdAmount,
                change: 0,
              },
            ];
          }
        }
      }

      return newState;
    });
  };

  const intro = useMemo(
    () =>
      lastAnswer === 0 ? (
        <>
          Ты положил(а) деньги на вклад, т.к. считаешь, что на валюте
          зарабатывают только спекулянты. Твои же деньги лежат в банке, приносят
          небольшой процент, зато все стабильно и надежно. Это хорошая
          консервативная стратегия. Но ради интереса ты решаешь посмотреть курс
          доллара. {usdRate} рублей!!! Это же сколько можно было заработать,
          если бы ты купил(а) доллар по {prevUsdRate} рубля?
        </>
      ) : (
        <>
          А доллар-то подрос! <br /> Стоит {usdRate} рублей. Твои друзья верят в
          дальнейший рост, поэтому закупились.
        </>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const question = useMemo(
    () =>
      lastAnswer === 0 ? (
        <>
          У тебя есть {formatAmount(startAmount)} рублей. Ты снова перед
          выбором.
        </>
      ) : (
        <>Что сделаешь ты?</>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const answers = useMemo(
    () =>
      lastAnswer === 0
        ? [
            {
              id: '1',
              title:
                'Куплю, наконец, доллары. Если так пойдет и дальше, разбогатею.',
            },
            {
              id: '2',
              title: 'Не буду вкладываться. Слишком быстрый рост, явно упадет.',
            },
          ]
        : [
            {
              id: '1',
              title: 'Продам все свои доллары.',
            },
            {
              id: '2',
              title: `Куплю еще на ${formatAmount(
                startAmount,
              )} рублей. Друзья заработают, а я что ли нет?`,
            },
          ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <StoryPart
      selectedAnswer={answerIndex}
      partNumber={partIndex + 1}
      partDate={date}
      onClickAnswer={handleSelectAnswer}
      onClickButton={handleClickButton}
      intro={intro}
      question={question}
      answers={answers}
    />
  );
};
