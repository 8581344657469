import React, { useContext } from 'react';
import { StoryPart } from '#components';
import { GameContext } from '#pages/game';
import { UseGameReturnType } from '#hooks';
import { formatAmount } from '#utils';
import { VIDEO_LINKS } from '#constants/videoList';

export const Part1: React.FC = () => {
  const {
    partIndex,
    answerIndex,
    handleSelectAnswer,
    gotoNext,
    partData: {
      cur: { startAmount, date },
    },
  } = useContext(GameContext) as UseGameReturnType;

  const handleClickButton = () => {
    gotoNext(state => {
      if (answerIndex === 1) {
        return { ...state, cash: startAmount, expenses: startAmount };
      }
      if (answerIndex === 2) {
        return {
          ...state,
          expenses: startAmount,
          assets: {
            ...state.assets,
            deposits: { ...state.assets.deposits, threeYears: startAmount },
          },
        };
      }
      return state;
    });
  };

  return (
    <StoryPart
      selectedAnswer={answerIndex}
      partNumber={partIndex + 1}
      partDate={date}
      onClickAnswer={handleSelectAnswer}
      onClickButton={handleClickButton}
      intro={
        <>
          Игра начинается 5 января – в первые дни нового года, время, когда
          ставятся цели и пишутся планы на целый год вперед.
          <br />
          Тебе 18 лет, ты совсем недавно поступил(а) в университет. Ты живешь с
          родителями, особо ни в чем не нуждаешься.
        </>
      }
      question={
        <>
          На новый год тебе подарили {formatAmount(startAmount)} рублей.
          <br /> Что ты с ними сделаешь?
        </>
      }
      answers={[
        {
          title: 'Куплю новую одежду',
        },
        { title: 'Ничего пусть лежат' },
        {
          title: 'Положу на вклад на 3 года под 9%',
          link: VIDEO_LINKS.deposit,
        },
      ]}
    />
  );
};
