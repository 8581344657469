import React, { memo, useEffect, useMemo, useState } from 'react';
import { formatAmount } from '#utils/formatAmount';
import { ModalAsset, ModalAssetName, ModalAssetType } from '#hooks';
import { MinusSVG, PlusSVG } from '#assets/svg';
import { STOCKS_DATA } from '#constants/assetsData';
import { isStocksName } from '#utils/isAssetName';

const ASSET_ICONS = {
  stocks: require('#assets/stocks.png'),
  bonds: require('#assets/bonds.png'),
  etf: require('#assets/etf.png'),
};

export interface AssetsModalItemProps {
  title: string;
  name: ModalAssetName;
  type: ModalAssetType;
  amount: number;
  price: number;
  index: number;
  balance: number;
  setBalance: React.Dispatch<React.SetStateAction<number>>;
  onAssetChange: (asset: ModalAsset) => void;
}

export const AssetsModalItem: React.FC<AssetsModalItemProps> = memo(
  ({
    title,
    name,
    type,
    price,
    index,
    onAssetChange,
    balance,
    setBalance,
    amount: externalAmount,
  }) => {
    const [inputValue, setInputValue] = useState(externalAmount.toString());
    const [amount, setAmount] = useState<number>(externalAmount);
    const [isEditable, setIsEditable] = useState(Boolean(externalAmount));

    const assetLot = isStocksName(name) ? STOCKS_DATA[name].lot : 1;
    const isEnoughToBuy = balance - price * assetLot >= 0;

    const handleInputBlur = () => {
      const newInputValue = amount / assetLot;
      setInputValue(newInputValue.toString());
      if (newInputValue === 0) {
        setIsEditable(false);
      }
    };

    const handleAddToPortfolio = () => {
      if (amount) return;
      const cost = price * assetLot;
      const newInputValue = 1;

      const isEnough = cost <= balance;

      if (isEnough) {
        const newAmount = amount + assetLot;
        const newBalance = balance - cost;

        setAmount(newAmount);
        setBalance(newBalance);
        setInputValue(newInputValue.toString());
        setIsEditable(true);
      }
    };

    const handleDecreaseAmount = () => {
      const cost = price * assetLot;
      const newAmount = amount - assetLot;

      if (newAmount > 0) {
        const newBalance = balance + cost;
        const newInputValue = newAmount / assetLot;

        setAmount(newAmount);
        setBalance(newBalance);
        setInputValue(newInputValue.toString());
      }
    };

    const handleIncreaseAmount = () => {
      const cost = price * assetLot;
      const isEnough = cost <= balance;

      if (isEnough) {
        const newAmount = amount + assetLot;
        const newInputValue = newAmount / assetLot;
        const newBalance = balance - cost;

        setAmount(newAmount);
        setBalance(newBalance);
        setInputValue(newInputValue.toString());
      }
    };

    const handleBuyMax = () => {
      const cost = price * assetLot;
      const additionalLotsAmount = Math.floor(balance / cost);
      const additionalAmount = additionalLotsAmount * assetLot;

      if (additionalLotsAmount) {
        const newInputValue = parseInt(inputValue) + additionalLotsAmount;
        const newBalance = balance - additionalLotsAmount * cost;
        const newAmount = amount + additionalAmount;

        setAmount(newAmount);
        setBalance(newBalance);
        setInputValue(newInputValue.toString());
      }
    };

    const handleRemoveFromPortfolio = () => {
      const cashBack = amount * price;
      const newBalance = cashBack + balance;

      setIsEditable(false);
      setBalance(newBalance);
      setAmount(0);
      setInputValue('0');
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newLotsAmount = parseInt(e.target.value.replace(/[^0-9]/g, ''));
      const newInputValue = isNaN(newLotsAmount)
        ? ''
        : newLotsAmount.toString();

      const cost = price * assetLot;
      const totalCost = newLotsAmount * cost;
      const difference = amount - newLotsAmount * assetLot;

      const isIncrease = difference < 0;
      const isDecrease = difference > 0;
      const isEnough = totalCost <= balance;

      if ((isIncrease && isEnough) || isDecrease) {
        setAmount(prev => prev - difference);
        setBalance(prev => prev + difference * price);
        setInputValue(newInputValue);
        return;
      }

      setInputValue(newInputValue);
    };

    useEffect(() => {
      onAssetChange({ name, price, amount, change: 0 });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [amount]);

    const AssetIcon = () => (
      <img src={ASSET_ICONS[type]} className="w-[46px] h-[46px]" alt={type} />
    );

    const newTitle = useMemo(() => {
      const [_, ...restWords] = title.split(' ');
      if (type === 'etf') {
        return title;
      }
      return restWords.join(' ');
    }, [title, type]);

    return (
      <div
        className={`flex items-center gap-[15px] w-full h-full p-[20px] px-[20px] ${
          index === 0 ? 'border-t border-b' : 'border-b'
        } ${amount !== 0 ? 'bg-gray-100' : 'cursor-pointer'}`}
        onClick={() => handleAddToPortfolio()}
      >
        <AssetIcon />
        <div className="flex flex-col w-full h-full gap-[9px]">
          <div className="flex justify-between items-center w-full text-m font-semibold">
            <span>{newTitle}</span>
            <span className="whitespace-nowrap">
              {formatAmount(price, '₽/шт.')}
            </span>
          </div>
          <div className="w-full h-[17px] flex justify-between items-center gap-[15px] text-sm">
            {isEditable ? (
              <>
                <div className="flex justify-between items-center xl:w-[176px] w-full">
                  <button
                    className="flex items-center text-sm underline text-afina-purple"
                    onClick={handleRemoveFromPortfolio}
                  >
                    Удалить
                  </button>
                  <div className="flex border border-gray-300 rounded-l-lg rounded-r-lg gap-[1px] ml-[20px] bg-gray-200">
                    <button className="p-[2px]" onClick={handleDecreaseAmount}>
                      <MinusSVG width={15} className="fill-gray-500 " />
                    </button>
                    <input
                      className="text-center font-['ChivoMono_Regular'] w-[60px] m-0 border-gray-300 border-l border-r"
                      value={inputValue}
                      onBlur={handleInputBlur}
                      onChange={handleInputChange}
                      onKeyDown={e =>
                        ['ArrowUp', 'ArrowDown', 'e', 'E'].includes(e.key) &&
                        e.preventDefault()
                      }
                      title="Количество лотов"
                      type="number"
                      step="1"
                      min="0"
                    />
                    <button
                      className="p-[2px] border-gray-300 border-r rounded-r-lg"
                      onClick={handleIncreaseAmount}
                    >
                      <PlusSVG width={15} className="fill-gray-500 " />
                    </button>
                    <button
                      className="py-[2px] pl-[10px] pr-[7px] flex items-center"
                      title="На все!"
                      onClick={handleBuyMax}
                    >
                      <span className="text-gray-500 text-xs">max</span>
                    </button>
                  </div>
                </div>
                <span className="text-right whitespace-nowrap xl:inline hidden">
                  {(isStocksName(name) ? `${amount} шт. ` : '') +
                    `на ${formatAmount(amount * price, '₽')}`}
                </span>
              </>
            ) : (
              <>
                <button
                  className={`flex text-sm ${
                    isEnoughToBuy
                      ? 'text-afina-green underline'
                      : 'text-gray-500 cursor-default'
                  }`}
                  onClick={handleAddToPortfolio}
                >
                  {isEnoughToBuy ? 'Выбрать' : 'Недостаточно средств'}
                </button>
                {isStocksName(name) ? (
                  <span className="text-gray-500">1 лот = {assetLot} шт.</span>
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>
    );
  },
);
