import { BONDS_DATA, ETFS_DATA, STOCKS_DATA } from '#constants';
import { BondsName, PairName, StocksName } from '#types';

export const isBondsName = (pairName: PairName): pairName is BondsName => {
  return pairName in BONDS_DATA;
};

export const isStocksName = (pairName: PairName): pairName is StocksName => {
  return pairName in STOCKS_DATA;
};

export const isEtfName = (pairName: PairName): pairName is StocksName => {
  return pairName in ETFS_DATA;
};

