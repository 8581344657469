export const formatAmount = (
  amount: number,
  suffix?: string,
  fixedTwo = false,
) => {
  const isInt = Number.isInteger(+amount.toFixed(2));
  const fixedAmount = amount.toFixed(isInt && !fixedTwo ? 0 : 2).replace('.', ',');
  return `${fixedAmount.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}${
    suffix ? ' ' + suffix : ''
  }`;
};
