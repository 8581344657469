import React, { useContext } from 'react';
import { StoryPart } from '#components';
import { GameContext } from '#pages/game';
import { UseGameReturnType } from '#hooks';
import { INITIAL_ASSETS, VIDEO_LINKS } from '#constants';

export const Part4: React.FC = () => {
  const {
    partIndex,
    answerIndex,
    handleSelectAnswer,
    gotoNext,
    assetsTotalRubAmount,
    partData: {
      cur: { date },
    },
  } = useContext(GameContext) as UseGameReturnType;

  const handleClickButton = () => {
    gotoNext(state => {
      if (answerIndex === 0) {
        return {
          ...state,
          cash: 0,
          assets: {
            ...INITIAL_ASSETS,
            deposits: {
              ...INITIAL_ASSETS.deposits,
              invest: assetsTotalRubAmount,
            },
          },
        };
      }

      return state;
    });
  };

  return (
    <StoryPart
      selectedAnswer={answerIndex}
      partNumber={partIndex + 1}
      partDate={date}
      onClickAnswer={handleSelectAnswer}
      onClickButton={handleClickButton}
      intro={
        <>
          В этом году ты заканчиваешь университет (бакалавриат). У тебя уже есть
          постоянная работа. Снимаешь квартиру, но денег, конечно же, мало.
          Хочется больше. И платить хочется за свое жилье, хоть и ипотечное, а
          не съемное. Для покупки собственной квартиры необходим первоначальный
          взнос. Поэтому нужен какой-то более выгодный способ накопления, чем
          банковский вклад, но при этом, чтобы нельзя было легко снять деньги. И
          оказалось, что такой инструмент существует – индивидуальный
          инвестиционный счет (ИИС).
        </>
      }
      question={
        <>
          Ты решаешь его открыть и положить все свои деньги.
          <br /> Счет будет приносить доход 3 года.
          <br /> Во 2-й и 3-й год ты планируешь вносить еще по 150 000 рублей.
        </>
      }
      answers={[
        {
          title: 'Открываю!',
          link: VIDEO_LINKS.iis,
        },
        {
          title:
            'Ничего не понятно. Я же универ заканчиваю, надо отдохнуть. Это будет лучшее лето!',
        },
      ]}
    />
  );
};
