import React, { ReactNode, memo, useContext } from 'react';
import { Button } from '#components';
import { NavLink } from 'react-router-dom';
import { PlaySVG } from '#assets/svg';
import { GameContext } from '#pages/game';
import { UseGameReturnType } from '#hooks/useGame';
import Modal from 'react-modal';

interface StoryPartProps {
  intro: ReactNode;
  partNumber: number;
  partDate: string;
  question: ReactNode;
  answers: {
    title: string;
    link?: { title: string; to: string };
  }[];
  selectedAnswer: number | null;
  buttonCaption?: string;
  onClickAnswer: (index: number) => void;
  onClickButton: () => void;
}

export const StoryPart: React.FC<StoryPartProps> = memo(
  ({
    intro,
    partNumber,
    partDate,
    question,
    answers,
    selectedAnswer,
    buttonCaption = 'Продолжить',
    onClickAnswer,
    onClickButton,
  }) => {
    const {
      showTimeEnd,
      gotoNext,
      partData: {
        cur: { startAmount },
      },
    } = useContext(GameContext) as UseGameReturnType;
    const modalStyle = {
      content: {
        maxWidth: '489px',
        maxHeight: '636px',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto',
        borderRadius: '24px',
        padding: 0,
        overflow: 'hidden',
        display: 'flex',
        height: 'fit-content',
      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 10,
      },
    };
    return (
      <section className="w-full pb-[50px] flex flex-col items-center justify-between px-[30px] gap-[10px]">
        <header className="gap-[16px]">
          <h3 className="text-center uppercase">{`История №${partNumber}`}</h3>
          <h5 className="text-center mt-[16px]">{partDate}.</h5>
        </header>
        <h5 className="text-center mt-[16px] mb-[10px] xl:mb-0">{intro}</h5>
        <h3 className="text-center uppercase">{question}</h3>
        <div className="flex xl:flex-row flex-col item-center justify-center w-full xl:gap-[50px] gap-[25px] xl:mb-[20px] mb-[30px] xl:mt-0 mt-[30px]">
          {answers.map(({ title, link }, index) => (
            <div key={title} className="relative xl:w-[320px] flex justify-center">
              <Button
                onClick={() => onClickAnswer(index)}
                className={`relative transition-all duration-500 gap-[30px] overflow-hidden ${
                  selectedAnswer === index
                    ? '!bg-answer-active !border-answer-active shadow-xl transform transition scale-105'
                    : ''
                } ${
                  selectedAnswer !== index && link
                    ? '!bg-answer-with-link-default'
                    : ''
                } ${
                  selectedAnswer === index && link
                    ? '!bg-answer-with-link-active'
                    : ''
                }`}
                isAnswer
              >
                <p className="justify-self-center m-auto">{title}</p>
                {link && (
                  <NavLink
                    to={link.to}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="justify-self-end flex justify-center items-center gap-[12px] text-base text-afina-purple underline z-[1]"
                    onClick={e => e.stopPropagation()}
                  >
                    <PlaySVG width={10} />
                    Урок: {link.title}
                  </NavLink>
                )}
              </Button>
            </div>
          ))}
        </div>
        <Button disabled={selectedAnswer == null} onClick={onClickButton}>
          {buttonCaption}
        </Button>
        <Modal isOpen={showTimeEnd} style={modalStyle} ariaHideApp={false}>
          <div className="flex flex-col flex-grow justify-center items-center overflow-hidden p-[40px] gap-[20px]">
            <h3 className="text-center">Время на принятие решения истекло</h3>
            <p className="mb-[15px] text-center">
              {startAmount
                ? 'Ты не придумал, как использовать свои деньги, поэтому они будут просто лежать и ждать своего часа.'
                : 'Но ничего не делать - тоже решение.'}
            </p>
            <Button onClick={() => gotoNext(state => state)}>Понятно</Button>
          </div>
        </Modal>
      </section>
    );
  },
);
