import React, { PropsWithChildren, FC, useMemo } from 'react';

interface BackgroundProps extends PropsWithChildren {
  isNotGame: boolean;
}

export const Background: FC<BackgroundProps> = ({ children, isNotGame }) => {
  const bgClassName = useMemo(
    () => ({
      background: `min-h-screen h-full w-full -z-10 transition-all duration-[1500ms] ${
        isNotGame ? 'main-background' : 'bg-background-game'
      }`,
      contentWrapper: `h-full w-full flex flex-col px-[20px] overflow-y-hidden mx-auto ${
        isNotGame ? 'xl:w-[1400px]' : 'flex-grow'
      }`,
    }),
    [isNotGame],
  );
  return (
    <>
      <div className={bgClassName.background}>
        <div className={bgClassName.contentWrapper}>{children}</div>
      </div>
    </>
  );
};
