import React, { useContext, useMemo } from 'react';
import { Button, TotalChart, AssetsChart, Rating } from '#components';
import { GameContext } from '#pages/game';
import { UseGameReturnType } from '#hooks';
import { formatAmount } from '#utils/formatAmount';
import author from '#assets/author.png';
import { ShareArrowSVG, TelegramSVG, VkSVG, WavyArrowSVG } from '#assets/svg';
import { NavLink } from 'react-router-dom';
import { VIDEO_LINKS } from '#constants';

const SHARE_URL = window.location.origin;
const SHARE_TITLE = 'Сыграй и узнаешь!';
const SHARE_COMMENT =
  'Наиграл(а) на миллион. А сколько сможешь накопить ты за 10 лет?';
const SHARE_IMG = SHARE_URL + require('#assets/main.png');

export const Part11: React.FC = () => {
  const {
    restAssetsList,
    etfTotalRubAmount,
    bondsTotalRubAmount,
    stocksTotalRubAmount,
    totalProfitability,
    assetsTotalRubAmount,
    liabilitiesTotalAmount,
    state: { cash, assetsInflated, liabilities },
  } = useContext(GameContext) as UseGameReturnType;

  const isNegativeProfitability = totalProfitability < 0;

  const assetsChartData = useMemo(() => {
    const getDisplayValue = (value: number) => {
      const percent = (value / assetsTotalRubAmount) * 100;
      return percent.toFixed(2);
    };

    return [
      ...restAssetsList.map(asset => ({
        id: asset.title,
        label: asset.title,
        value: getDisplayValue(asset.amount),
        amount: +asset.amount.toFixed(2),
      })),
      ...(stocksTotalRubAmount
        ? [
            {
              id: 'Акции',
              label: 'Акции',
              value: getDisplayValue(stocksTotalRubAmount),
              amount: Math.round(stocksTotalRubAmount),
            },
          ]
        : []),
      ...(bondsTotalRubAmount
        ? [
            {
              id: 'Облигации',
              label: 'Облигации',
              value: getDisplayValue(bondsTotalRubAmount),
              amount: Math.round(bondsTotalRubAmount),
            },
          ]
        : []),
      ...(etfTotalRubAmount
        ? [
            {
              id: 'ETF',
              label: 'ETF',
              value: getDisplayValue(etfTotalRubAmount),
              amount: Math.round(etfTotalRubAmount),
            },
          ]
        : []),
    ].sort((a, b) => b.amount - a.amount);
  }, [
    assetsTotalRubAmount,
    bondsTotalRubAmount,
    etfTotalRubAmount,
    restAssetsList,
    stocksTotalRubAmount,
  ]);

  const totalData = useMemo(
    () => [
      {
        id: 'Активы',
        label: 'Активы',
        value: Math.round(
          (assetsTotalRubAmount /
            (assetsTotalRubAmount + liabilitiesTotalAmount)) *
            100,
        ),
      },
      {
        id: 'Пассивы',
        label: 'Пассивы',
        value: Math.round(
          (liabilitiesTotalAmount /
            (assetsTotalRubAmount + liabilitiesTotalAmount)) *
            100,
        ),
      },
    ],
    [assetsTotalRubAmount, liabilitiesTotalAmount],
  );

  const hasRealEstate = liabilities.some(l => l.name === 'mortgage');
  const hasCar = liabilities.some(
    l => l.name === 'car' || l.name === 'personal',
  );

  const cost = assetsTotalRubAmount - liabilitiesTotalAmount;
  const isNegativeCost = cost < 0;

  return (
    <div className="flex flex-col justify-center items-start gap-[20px] w-full h-full xl:py-[50px]">
      <h1>
        Поздравляем!
        <br /> Вы закончили игру!
      </h1>
      <div className="flex flex-col xl:gap-0 gap-[20px] xl:my-0 my-[20px]">
        <TotalChart
          totalData={totalData}
          liabilitiesTotal={formatAmount(liabilitiesTotalAmount, '₽')}
          assetsTotal={formatAmount(assetsTotalRubAmount, '₽')}
        />
        <AssetsChart
          assetsData={assetsChartData}
          assetsTotal={formatAmount(assetsTotalRubAmount, '₽')}
        />
      </div>
      <div className="max-w-[1000px] flex flex-col justify-center items-start gap-[20px]">
        <h5 className="text-afina-purple uppercase">Результаты</h5>
        {!!cash && (
          <p>
            У тебя накопилось: {formatAmount(assetsTotalRubAmount, '₽ ')}. Но
            важно понимать, что стоимость денег все эти годы менялась (привет,
            инфляция!). Реальных денег в твоем капитале:{' '}
            {formatAmount(assetsTotalRubAmount - assetsInflated, '₽')}.
            <br />
            <br />
            Но не стоит расстраиваться, ведь стоимость твоих активов тоже
            менялась!
          </p>
        )}
        <p>
          Вспомни, сколько ты вложил(а) в каждый инструмент, и посмотри
          внимательно свой капитал сейчас.
        </p>
        {!!hasRealEstate && (
          <p>
            Твоя квартира выросла в цене. И сейчас она стоит примерно 4,9 млн.
            руб. Неплохо, да?
          </p>
        )}
        {!!hasCar && (
          <p>
            Твой автомобиль, кстати, тоже вырос в цене, несмотря на то, что он
            все это время старел. Его цена сейчас примерно 700 тыс. руб.
          </p>
        )}
        <p>Теперь давай посмотрим доходность твоих 10-летних вложений:</p>
        <h5
          className={` uppercase ${
            isNegativeProfitability ? 'text-afina-burgundy' : 'text-afina-green'
          }`}
        >
          {`${isNegativeProfitability ? '-' : '+'} ${formatAmount(
            totalProfitability,
            '%',
          )}`}
        </h5>
        {isNegativeProfitability && (
          <p>
            Отрицательная доходность показывает, что ты принял(а) несколько
            неверных финансовых решений. Такое бывает со всеми. Главное - ты
            получил(а) важный опыт, который в будущем поможет избежать этих
            ошибок и прийти к приличным суммам на своих счетах.
          </p>
        )}
        {totalProfitability >= 0 && totalProfitability < 50 && (
          <p>
            Видно, что ты старался(ась) и даже смог не растерять заработанное и
            накопленное. Следующим шагом следуюет подумать о том, как можно
            увеличивать свои накопления. После этой игры ты знаешь несколько
            инструментов.
          </p>
        )}
        {totalProfitability >= 50 && totalProfitability < 100 && (
          <p>
            Хороший результат. Настоящая консервативная стратегия. Не потерял(а)
            деньги, еще и заработала на них. Отлично! Подумай о том, чтобы
            добавить в свой портфель более рискованные инструменты, они могут
            принести большую доходность. Но не забывай про концепцию{' '}
            <a
              href={VIDEO_LINKS.risk.to}
              target="_blank"
              rel="noreferrer noopener"
            >
              «Риск-доходность»
            </a>
            .
          </p>
        )}
        {totalProfitability >= 100 && totalProfitability < 200 && (
          <p>
            Приятные цифры, неправда ли? Молодец! Видно, что с деньгами ты на
            одной волне. А это значит, что в своей стратегии ты можешь
            использовать еще больше разных инстументов. Подумай об этом.
          </p>
        )}
        {totalProfitability >= 200 && totalProfitability < 500 && (
          <p>
            Ого! Ты это видел(а)? Ты почти Уоррен Баффет! Уверена, что и в жизни
            с твоими деньгами все ок, а если и нет, то скоро ты точно улучшить
            ситуацию!
          </p>
        )}
        {totalProfitability >= 500 && (
          <p>
            Да ты просто Бог инвестиций! Продолжай в том же духе, и деньги у
            тебя будут всегда!
          </p>
        )}
        <p>
          И последний вывод, который может тебя шокировать. Давай посмотрим
          стоимость этих 10 лет, результат немаленького периода жизни. И это:
        </p>
        <h5
          className={` uppercase ${
            isNegativeCost ? 'text-afina-burgundy' : 'text-afina-green'
          }`}
        >
          {`${isNegativeCost ? '-' : '+'} ${formatAmount(Math.abs(cost), '₽')}`}
        </h5>
        <p>
          Это число - разница между твоими активами и пассивами. Если она
          положительная - отлично, если равна нулю - стоит задуматься, а вот
          если отрицательная - пора что-то менять в своей жизни!
        </p>
        <p>
          Наша команда уверена, что эта игра открыла тебе глаза на свою
          финансовую ситуацию. 10 лет в игре ты можешь проживать сколько угодно
          раз, а вот в жизни - всего один. Не упусти возможности. Бери свои
          финансы в свои руки, откладывай, вкладывай, управляй, преумножай! И
          помни, что время работает на тебя, чем раньше начнешь, тем больше
          получишь. Успехов!
        </p>
      </div>
      <div className="relative flex xl:flex-row flex-col items-center justify-between w-full mb-[60px]">
        <div className="flex flex-col gap-[60px] w-full xl:items-start items-center">
          <div className="flex flex-col gap-[30px] xl:items-start items-center">
            <p>Оцени на сколько ты доволен своими финансовыми решениями </p>
            <Rating />
          </div>

          <div className="flex flex-col gap-[30px]">
            <p>Поделись результатом с друзьями в соцсети</p>
            <Button
              className="w-[340px]"
              onClick={() => {
                window.open(
                  `http://vk.com/share.php?url=${SHARE_URL}&title=${SHARE_TITLE}&comment=${SHARE_COMMENT}&image=${SHARE_IMG}&noparse=true`,
                  '_blank',
                );
              }}
            >
              <ShareArrowSVG className="mr-[13px]" />
              Поделиться с друзьями
            </Button>
          </div>

          <div className="flex flex-col gap-[30px]">
            <p className="xl:text-left text-center">
              Также советуем подписаться на автора этой игры
            </p>
            <div className="flex xl:justify-start justify-center gap-[30px]">
              <NavLink
                to={'https://t.me/plan_afina '}
                target="_blank"
                rel="noreferrer noopener"
              >
                <TelegramSVG className="hover:cursor-pointer" />
              </NavLink>
              <NavLink
                to={'https://vk.com/plan_afina'}
                target="_blank"
                rel="noreferrer noopener"
              >
                <VkSVG className="hover:cursor-pointer" />
              </NavLink>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center gap-3 xl:mt-0 mt-[50px]">
          <img
            src={author}
            alt="author_pic"
            className="xl:max-w-[400px] h-auto m-1"
          />
          <h4>Анастасия Преснякова</h4>
          <p className="text-center">
            финансовый консультант, наставник,
            <br />
            квалифицированный инвестор
          </p>
        </div>
        <WavyArrowSVG className="absolute bottom-0 left-0 right-0 mx-auto xl:block hidden" />
      </div>
    </div>
  );
};
