import React, { IframeHTMLAttributes, memo } from 'react';

interface YouTubeProps extends Omit<IframeHTMLAttributes<never>, 'src'> {
  videoId: string;
}

export const YouTube: React.FC<YouTubeProps> = memo(
  ({
    videoId,
    className,
    width = 640,
    height = 360,
    allow = 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share',
    allowFullScreen = true,
    title = 'Youtube video player',
    ...props
  }) => {
    return (
      <iframe
        width={width}
        height={height}
        src={`https://www.youtube.com/embed/${videoId}`}
        allow={allow}
        allowFullScreen={allowFullScreen}
        title={title}
        className={className}
        {...props}
      />
    );
  },
);
