import { BondsName, EtfName, StocksName } from '#types/AssetNames';
import { Pair } from '#types/Assets';
import { useState } from 'react';

export type ModalAssetType = 'stocks' | 'bonds' | 'etf';

export type ModalAssetName = StocksName | BondsName | EtfName;

export interface ModalAsset extends Pair {
  name: ModalAssetName;
  price: number;
}

export interface UseAssetsModalProps {
  startAmount: number;
}

export const useAssetsModal = ({ startAmount }: UseAssetsModalProps) => {
  const [modalType, setModalType] = useState<ModalAssetType | null>(null);
  const [selectedAssets, setSelectedAssets] = useState<ModalAsset[]>([]);
  const [remainingBalance, setRemainingBalance] = useState(startAmount);

  const onModalClose = () => {
    setModalType(null);
    setSelectedAssets([]);
    setRemainingBalance(startAmount);
  };

  const reset = () => {
    setSelectedAssets([]);
    setRemainingBalance(startAmount);
  };

  return {
    modalType,
    selectedAssets,
    remainingBalance,
    setModalType,
    setSelectedAssets,
    setRemainingBalance,
    onModalClose,
    reset,
  };
};
