import { AssetData, LiabilityName } from '#types';

export const LIABILITIES_DATA: Record<LiabilityName, AssetData> = {
  car: { title: 'Автокредит', suffix: '₽' },
  personal: { title: 'Потребительский кредит', suffix: '₽' },
  mortgage: { title: 'Ипотека', suffix: '₽' },
};

export const PROPERTIES_DATA: Record<LiabilityName, AssetData> = {
  car: { title: 'Автомобиль', suffix: '₽' },
  personal: { title: 'Автомобиль', suffix: '₽' },
  mortgage: { title: 'Недвижимость', suffix: '₽' },
};
