import { Button, TextContentWrapper } from '#components';
import React from 'react';
import author from '#assets/author.png';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES_ENUM } from '#components/Router';

const LINK_LIST: { title: string; to: string }[] = [
  {
    title: 'Канал про финансы и инвестиции в телеграм — ',
    to: 'https://t.me/plan_afina',
  },
  {
    title: 'Группа Вконтакте — ',
    to: 'https://vk.com/plan_afina',
  },
  {
    title: 'Ссылка на аккаунт в телеграм — ',
    to: 'https://t.me/A_Presnyakova',
  },
  {
    title:
      'В случае возникновения ошибок в работе игры сообщать на эл. почту — ',
    to: 'a.d.presnyakova@gmail.com',
  },
];

export const Contacts: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(ROUTES_ENUM.GAME);
  };

  const Image = ({ className }: { className: string }) => (
    <div
      className={`flex flex-col justify-center items-center gap-3 w-full ${className}`}
    >
      <img
        src={author}
        alt="author_pic"
        className="xl:max-w-[400px] h-auto m-1"
      />
      <h4>Анастасия Преснякова</h4>
      <p className="text-center">
        Автор игры — финансовый консультант, наставник,
        <br />
        квалифицированный инвестор
      </p>
    </div>
  );

  return (
    <div className="flex h-full w-full xl:flex-row flex-col-reverse justify-start items-center">
      <TextContentWrapper>
        <h1>Контакты</h1>
        {LINK_LIST.map(({ title, to }) => (
          <p key={to}>
            {title}
            <Link
              className="text-afina-purple"
              to={to.includes('@') ? `mailto:${to}` : to}
            >
              {to}
            </Link>
          </p>
        ))}
        <Image className="xl:hidden" />
        <Button className='xl:mt-0 mt-[15px]' onClick={handleClick}>Начать игру</Button>
      </TextContentWrapper>
      <Image className="xl:flex hidden" />
    </div>
  );
};
