import React, { useMemo } from 'react';
import { MayHaveLabel, ResponsivePie } from '@nivo/pie';
import { takeModulo } from '#utils';
import { useBreakpoint } from '#hooks/useBreakpoint';

type AssetsChartProps = {
  assetsData: Array<MayHaveLabel & { value: number | string; amount: number }>;
  assetsTotal: string | number;
  className?: string;
};

const ASSET_COLORS = ['#F9DCC3', '#F6B94A', '#F5AB21', '#F7C473'];

export const AssetsChart: React.FC<AssetsChartProps> = ({
  assetsData,
  assetsTotal,
  className = '',
}) => {
  const pieData = useMemo(
    () =>
      assetsData.map((item, index) => ({
        ...item,
        color: ASSET_COLORS[takeModulo(index + 1, ASSET_COLORS.length)],
      })),
    [assetsData],
  );

  const isXl = useBreakpoint('xl');

  const style = {
    width: isXl ? '1000px' : document.body.clientWidth - 40 + 'px',
    height: isXl ? '500px' : document.body.clientWidth - 100 + 'px',
  };

  const margin = {
    top: isXl ? 70 : 10,
    right: 0,
    bottom: isXl ? 70 : 10,
    left: 0,
  };

  const theme = {
    labels: {
      text: {
        fontSize: isXl ? '1.125rem' : '4vw',
        fontFamily: 'Raleway Medium',
      },
    },
  };

  const formatvalue = (value: string | number) => {
    const percentage = Number(value).toFixed(2).replace('.', ',')
    return percentage === '0,00' ? '≤ 0,01%' : percentage + '%'
  };

  return (
    <div className={`flex flex-col gap-[25px] ${className}`}>
      <div className={'relative flex'} style={style}>
        <ResponsivePie
          theme={theme}
          arcLabel={datum => `${Math.round(datum.value)}%`}
          colors={datum => datum.data.color}
          arcLinkLabelsColor={datum => datum.data.color}
          data={pieData}
          margin={margin}
          innerRadius={0.67}
          padAngle={0.7}
          cornerRadius={0}
          activeOuterRadiusOffset={8}
          borderWidth={1}
          borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
          enableArcLinkLabels={isXl}
          arcLinkLabelsSkipAngle={3}
          arcLinkLabelsStraightLength={20}
          arcLinkLabelsDiagonalLength={50}
          arcLinkLabelsTextColor="black"
          arcLinkLabelsThickness={1}
          arcLabelsSkipAngle={5}
          arcLabelsTextColor="white"
          tooltip={() => <></>}
        />
        <div className="absolute font-['Rubik_Medium'] text-2xl top-0 bottom-0 left-0 right-0 m-auto flex flex-col gap-[10px] items-center justify-center text-center pointer-events-none uppercase">
          <div
            className={`flex flex-col text-afina-yellow ${
              isXl ? 'text-xl' : 'text-[4.5vw] leading-[6vw]'
            }`}
          >
            <span>Активы</span>
            <span>{assetsTotal}</span>
          </div>
        </div>
      </div>
      {isXl ? null : (
        <table className="w-fit self-start border-separate border-spacing-x-[9px] border-spacing-y-[2px] text-sm">
          {pieData.map(item => (
            <tr key={item.label}>
              <td className="text-left">
                {formatvalue(item.value)}
              </td>
              <td>—</td>
              <td>{item.label}</td>
            </tr>
          ))}
        </table>
      )}
    </div>
  );
};
