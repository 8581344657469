import { useMediaQuery } from 'react-responsive';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../tailwind.js';

const fullConfig = resolveConfig(tailwindConfig);
const breakpoints = fullConfig.theme.screens;

type Breakpoint = 'sm' | 'md' | 'lg' | 'xl' | '2xl';

export function useBreakpoint(breakpoint: Breakpoint) {
  return useMediaQuery({
    query: `(min-width: ${breakpoints[breakpoint]})`,
  });
}
