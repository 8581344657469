import { DepositName } from '#types';
import {
  INITIAL_PROFITS,
  INITIAL_LIABILITIES,
  INITIAL_RATES,
  INITIAL_PROPERTIES,
} from '#constants/initialData';

export const TAX_DEDUTCION = 0.13;

export const DEPOSIT_RATES: Record<DepositName, number> = {
  twoYears: 1.08,
  threeYears: 1.09,
  invest: 1,
  savings: 1.05,
};

export const STORY_DATA: {
  date: string;
  startAmount: number;
  profits: typeof INITIAL_PROFITS;
  rates: typeof INITIAL_RATES;
  liabilities: typeof INITIAL_LIABILITIES;
  properties: typeof INITIAL_PROPERTIES;
  inflation: number;
}[] = [
  // 1 - 2013
  {
    date: '5 января 2013 года',
    startAmount: 30000,
    profits: INITIAL_PROFITS,
    rates: INITIAL_RATES,
    liabilities: INITIAL_LIABILITIES,
    properties: INITIAL_PROPERTIES,
    inflation: 0.066,
  },
  // 2 - 2014
  {
    date: '5 января 2014 года',
    startAmount: 50000,
    profits: INITIAL_PROFITS,
    rates: { ...INITIAL_RATES, usd: 33 },
    liabilities: INITIAL_LIABILITIES,
    properties: INITIAL_PROPERTIES,
    inflation: 0.0645,
  },
  // 3 - 2015
  {
    date: '16 декабря 2015 года',
    startAmount: 50000,
    profits: INITIAL_PROFITS,
    rates: { ...INITIAL_RATES, usd: 79 },
    liabilities: INITIAL_LIABILITIES,
    properties: INITIAL_PROPERTIES,
    inflation: 0.1136,
  },
  // 4 - 2016
  {
    date: '5 января 2016 года',
    startAmount: 0,
    profits: INITIAL_PROFITS,
    rates: { ...INITIAL_RATES, usd: 73 },
    liabilities: INITIAL_LIABILITIES,
    properties: INITIAL_PROPERTIES,
    inflation: 0.1291,
  },
  // 5 - 2017
  {
    date: '6 января 2017 года',
    startAmount: 150000,
    profits: INITIAL_PROFITS,
    rates: { ...INITIAL_RATES, usd: 59, btc: 66988 },
    liabilities: INITIAL_LIABILITIES,
    properties: INITIAL_PROPERTIES,
    inflation: 0.0538,
  },
  // 6 - 2018
  {
    date: '5 января 2018 года',
    startAmount: 150000,
    profits: INITIAL_PROFITS,
    rates: { ...INITIAL_RATES, usd: 56, btc: 850085 },
    liabilities: INITIAL_LIABILITIES,
    properties: INITIAL_PROPERTIES,
    inflation: 0.0252,
  },
  // 7 - 2019
  {
    date: '5 января 2019 года',
    startAmount: 200000,
    profits: {
      ...INITIAL_PROFITS,
      sber: 13.92,
      gazp: 14.45,
      lkoh: 301.89,
      gmkn: 1984.06,
      alrs: 6.92,
      plzl: 266.74,
      chmf: 105.88,
      mgnt: 145.1,
      jnj: 302.59,
      aapl: 245.29,
      bac: 54.08,
      intc: 102.36,
      pm: 372.76,
      ofz: 73,
      yarobl: 73,
      rzd: 66,
    },
    rates: {
      ...INITIAL_RATES,
      usd: 66,
      btc: 250984,
      sber: 191.24,
      gazp: 158.29,
      lkoh: 5039,
      gmkn: 13128,
      alrs: 98.65,
      plzl: 5134.5,
      yndx: 1926.5,
      chmf: 952.7,
      mgnt: 3680,
      jnj: 8514,
      brkb: 12950,
      aapl: 2489,
      bac: 1681,
      intc: 3150,
      pm: 4479,
      ofz: 1001.6,
      yarobl: 974,
      rzd: 995,
    },
    liabilities: INITIAL_LIABILITIES,
    properties: INITIAL_PROPERTIES,
    inflation: 0.0427,
  },
  // 8 - 2020
  {
    date: '23 апреля 2020 года',
    startAmount: 50000,
    profits: {
      ...INITIAL_PROFITS,
      sber: 16.27,
      gazp: 13.26,
      lkoh: 344.52,
      gmkn: 1027.08,
      alrs: 2.29,
      plzl: 421.89,
      chmf: 92.56,
      mgnt: 264.65,
      jnj: 195.07,
      aapl: 79.19,
      bac: 34.77,
      intc: 63.74,
      pm: 229.84,
      ofz: 37,
      yarobl: 55,
      rzd: 34,
    },
    rates: {
      usd: 74,
      btc: 526347,
      sber: 190.94,
      gazp: 188.73,
      lkoh: 4902.5,
      gmkn: 20216,
      alrs: 62.87,
      plzl: 11951,
      yndx: 2640.6,
      chmf: 870,
      mgnt: 3287,
      jnj: 11507,
      brkb: 13736.62,
      aapl: 5088,
      bac: 1618.38,
      intc: 4368.96,
      pm: 5311.72,
      fxkz: 183,
      sbsp: 1137.2,
      trur: 5.32,
      ofz: 1193,
      yarobl: 1145,
      rzd: 1048,
    },
    liabilities: { ...INITIAL_LIABILITIES, mortgage: 2800000 },
    properties: INITIAL_PROPERTIES,
    inflation: 0.0305,
  },
  // 9 - 2021
  {
    date: '5 января 2021 года',
    startAmount: 0,
    profits: {
      ...INITIAL_PROFITS,
      sber: 16.269,
      gazp: 10.92,
      lkoh: 481.11,
      gmkn: 2213.62,
      alrs: 15.95,
      plzl: 569.53,
      chmf: 220.48,
      mgnt: 682.94,
      jnj: 429.35,
      aapl: 88.73,
      bac: 63.79,
      intc: 143.52,
      pm: 400.72,
      ofz: 74,
      yarobl: 91,
      rzd: 102,
    },
    rates: {
      usd: 74,
      btc: 2369136,
      sber: 273.82,
      gazp: 214.39,
      lkoh: 5311,
      gmkn: 24538,
      alrs: 99.3,
      plzl: 16017,
      yndx: 5263.6,
      chmf: 1333,
      mgnt: 5749,
      jnj: 11717,
      brkb: 16832,
      aapl: 9694.74,
      bac: 2239.24,
      intc: 3745.14,
      pm: 6096.86,
      fxkz: 237,
      sbsp: 1463.2,
      trur: 6.03,
      ofz: 1202,
      yarobl: 1099,
      rzd: 1071,
    },
    liabilities: {
      ...INITIAL_LIABILITIES,
      mortgage: 2744700,
    },
    properties: INITIAL_PROPERTIES,
    inflation: 0.0491,
  },
  // 10 - 2022
  {
    date: '28 февраля 2022 года',
    startAmount: 0,
    profits: {
      ...INITIAL_PROFITS,
      gazp: 44.4,
      lkoh: 689.91,
      gmkn: 1014.61,
      jnj: 212.35,
      aapl: 43.22,
      bac: 40.72,
      intc: 68.28,
      pm: 315.7,
      ofz: 74,
      yarobl: 55,
      rzd: 34,
    },
    rates: {
      usd: 94,
      btc: 3544476,
      sber: 130.72,
      gazp: 228.55,
      lkoh: 4921,
      gmkn: 19122,
      alrs: 80.07,
      plzl: 10619.5,
      yndx: 1934.4,
      chmf: 1315.2,
      mgnt: 3206,
      jnj: 15455,
      brkb: 30192,
      aapl: 15491.2,
      bac: 4145.4,
      intc: 4468.76,
      pm: 9452.64,
      fxkz: 237,
      sbsp: 1463.2,
      trur: 5.47,
      ofz: 794,
      yarobl: 900,
      rzd: 899,
    },
    liabilities: {
      mortgage: 2643800,
      personal: 409900,
      car: 482400,
    },
    properties: INITIAL_PROPERTIES,
    inflation: 0.0839,
  },
  // 11 - 2023
  {
    date: '5 января 2023 года',
    startAmount: 0,
    profits: INITIAL_PROFITS,
    rates: {
      usd: 72,
      btc: 1213358,
      sber: 141.31,
      gazp: 161.25,
      lkoh: 4085,
      gmkn: 15404,
      alrs: 58.85,
      plzl: 7860,
      yndx: 1844.4,
      chmf: 883.8,
      mgnt: 4325,
      jnj: 12883,
      brkb: 22535.28,
      aapl: 9006.48,
      bac: 2453.04,
      intc: 1988.64,
      pm: 7234.56,
      fxkz: 237,
      sbsp: 1463.2,
      trur: 5.91,
      ofz: 938,
      yarobl: 992.5,
      rzd: 999,
    },
    liabilities: {
      mortgage: 2567300,
      personal: 326400,
      car: 377700,
    },
    properties: INITIAL_PROPERTIES,
    inflation: 0.1192,
  },
];
