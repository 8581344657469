import React, { useEffect, useMemo } from 'react';
import Modal from 'react-modal';
import { CrossSVG } from '#assets/svg';
import { Button, AssetsModalItem } from '#components';
import { ASSETS_DATA } from '#constants/assetsData';
import { formatAmount } from '#utils/formatAmount';
import { ModalAsset, ModalAssetName, ModalAssetType } from '#hooks';

interface AssetsModalProps {
  assetType: ModalAssetType | null;
  currentRates: Record<ModalAssetName, number>;
  onRequestClose: () => void;
  onSubmit: () => void;
  selectedAssets: ModalAsset[];
  setSelectedAssets: React.Dispatch<React.SetStateAction<ModalAsset[]>>;
  remainingBalance: number;
  setRemainingBalance: React.Dispatch<React.SetStateAction<number>>;
}

export const AssetsModal: React.FC<AssetsModalProps> = ({
  assetType,
  currentRates,
  onRequestClose,
  onSubmit,
  selectedAssets,
  setSelectedAssets,
  remainingBalance,
  setRemainingBalance,
}) => {
  const isOpen = Boolean(assetType);

  const headerText = useMemo(() => {
    switch (assetType) {
      case 'bonds':
        return 'Выберите облигации';
      case 'etf':
        return 'Выберите ETF';
      case 'stocks':
        return 'Выберите акции';
      default:
        return '';
    }
  }, [assetType]);

  const modalStyle = useMemo(
    () => ({
      content: {
        maxWidth: '489px',
        maxHeight: '636px',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto',
        borderRadius: '24px',
        padding: 0,
        overflow: 'hidden',
        display: 'flex',
        height: 'fit-content',
      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 5,
      },
    }),
    [],
  );

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      if (isOpen) {
        document.body.style.overflow = 'unset';
      }
    };
  }, [isOpen]);

  const assetItems = useMemo(
    () =>
      assetType
        ? Object.entries(ASSETS_DATA[assetType]).map(
            ([assetName, { title }]) => {
              const name = assetName as ModalAssetName;
              const rate = currentRates[name];
              const selected = selectedAssets.find(a => a.name === name);
              return {
                title,
                name,
                type: assetType,
                price: Math.floor(rate),
                amount: selected ? selected.amount : 0,
              };
            },
          )
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [assetType],
  );

  const handleChangeAssets = (asset: ModalAsset) => {
    setSelectedAssets(prev => {
      const isDiffAmount = prev.some(a => a.name === asset.name);
      if (asset.amount === 0) {
        return [...prev.filter(a => a.name !== asset.name)];
      }
      if (isDiffAmount) {
        return [...prev.filter(a => a.name !== asset.name), asset];
      }
      return [...prev, asset];
    });
  };

  const handleCloseModal = () => {
    onRequestClose();
  };

  const handleSubmit = () => {
    if (!selectedAssets.length) return;
    onSubmit();
  };

  return (
    <Modal
      isOpen={isOpen}
      style={modalStyle}
      onRequestClose={handleCloseModal}
      shouldCloseOnOverlayClick
      ariaHideApp={false}
    >
      <div className="flex flex-col flex-grow justify-center items-center overflow-hidden">
        <header className="relative w-full flex items-center justify-center flex-grow pt-[30px] pb-[20px]">
          <span className="text-center font-semibold text-xl w-full">
            {headerText}
          </span>
          <CrossSVG
            className="absolute right-[36px] cursor-pointer"
            onClick={handleCloseModal}
            stroke="#AFB0B3"
          />
        </header>
        <section className="w-full h-full overflow-y-auto scrollbar flex flex-col">
          {assetItems.map(({ name, ...rest }, index) => (
            <AssetsModalItem
              key={name}
              name={name}
              index={index}
              onAssetChange={handleChangeAssets}
              balance={remainingBalance}
              setBalance={setRemainingBalance}
              {...rest}
            />
          ))}
        </section>
        <div className="relative w-full flex items-center justify-center gap-[20px] px-[20px] pt-[20px] pb-[10px] flex-grow">
          <span>Доступный остаток:</span>
          <span className="font-semibold">
            {formatAmount(remainingBalance, '₽')}
          </span>
        </div>
        <footer className="flex justify-center items-center w-full flex-grow py-[20px] px-[10px]">
          <Button onClick={handleSubmit} disabled={!selectedAssets.length}>
            Подтвердить
          </Button>
        </footer>
      </div>
    </Modal>
  );
};
