import { BONDS_DATA, ETFS_DATA, STOCKS_DATA } from '#constants/assetsData';
import { PairName } from '#types/AssetNames';
import { PairType } from '#types/Assets';

export const getPairType = (pairName: PairName): PairType => {
  if (pairName in BONDS_DATA) {
    return 'bonds';
  }
  if (pairName in STOCKS_DATA) {
    return 'stocks';
  }
  if (pairName in ETFS_DATA) {
    return 'etf';
  }
  return 'currency';
};
