import { formatAmount } from '#utils';
import React, { memo } from 'react';

interface CapitalProfitabilityProps {
  assetsTotal: number;
  expenses: number;
  profitability: number;
}

export const CapitalProfitability: React.FC<CapitalProfitabilityProps> = memo(
  ({ assetsTotal, expenses, profitability }) => {
    return (
      <div className="flex flex-col gap-[20px] mt-[30px] h-full w-full">
        <div className="flex justify-between items-center">
          <h5 className="text-afina-green">ДОХОДНОСТЬ:</h5>
          <h5 className="text-afina-green">
            {formatAmount(profitability, '%')}
          </h5>
        </div>
        <div className="flex flex-col gap-[22px]">
          <div className="flex justify-between items-center">
            <span>Стоимость активов</span>
            <span>{formatAmount(assetsTotal, '₽')}</span>
          </div>
          <div className="flex justify-between items-center">
            <span>Сумма вложений</span>
            <span>{formatAmount(expenses, '₽')}</span>
          </div>
        </div>
      </div>
    );
  },
);
