import React from 'react';
import main from '#assets/main.png';
import { Button, TextContentWrapper } from '#components';
import { useNavigate } from 'react-router-dom';
import { ROUTES_ENUM } from '#components/Router';

const ITEM_LIST = [
  'Готов ли ты откладывать деньги и создавать капитал?',
  'Или же ты из тех, кто тратит все здесь и сейчас?',
  'А может ты любишь вкладываться в сомнительные схемы?',
];

export const Main: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(ROUTES_ENUM.GAME);
  };

  return (
    <div className="flex h-full w-full xl:flex-row flex-col-reverse justify-start items-center">
      <TextContentWrapper>
        <h1>Накопи свой первый миллион</h1>
        <ul className="flex flex-col gap-[15px]">
          {ITEM_LIST.map(item => (
            <li key={item} className="text-lg gap-[10px]">
              <span className="text-afina-purple inline mr-[15px]">●</span>
              {item}
            </li>
          ))}
        </ul>
        <p>
          Игра покажет правду и научит тебя, как можно обеспечить себе богатое
          будущее.
        </p>
        <Button className='xl:mt-0 mt-[15px]' onClick={handleClick}>Начать игру</Button>
      </TextContentWrapper>
      <img className="xl:w-[55%] xl:mt-4 xl:mb-0 my-5" src={main} alt="pic" />
    </div>
  );
};
