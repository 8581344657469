import React, { useContext, useMemo } from 'react';
import { AssetsModal, StoryPart } from '#components';
import { GameContext } from '#pages/game';
import { UseGameReturnType, useAssetsModal } from '#hooks';
import { formatAmount } from '#utils';
import { STOCKS_DATA, VIDEO_LINKS } from '#constants';

export const Part7: React.FC = () => {
  const {
    partIndex,
    answerIndex,
    handleSelectAnswer,
    gotoNext,
    partData: {
      cur: { startAmount, date, rates },
    },
    lastAnswer,
  } = useContext(GameContext) as UseGameReturnType;

  const {
    modalType,
    selectedAssets,
    remainingBalance,
    setModalType,
    setSelectedAssets,
    setRemainingBalance,
    onModalClose,
    reset,
  } = useAssetsModal({ startAmount });

  const handleClickButton = () => {
    if (!selectedAssets.length) return;

    const incomePairs = selectedAssets.map(({ price: _, ...rest }) => rest);

    gotoNext(state => {
      const expenses = startAmount - remainingBalance;
      return {
        ...state,
        cash: state.cash + remainingBalance,
        expenses: state.expenses + expenses,
        assets: {
          ...state.assets,
          pairs: [...state.assets.pairs, ...incomePairs],
        },
      };
    });
  };

  const handleCloseModal = () => {
    onModalClose();
    handleSelectAnswer(null);
  };

  const handleClickAnswer = (index: number) => {
    if (answerIndex !== index) {
      reset();
      handleSelectAnswer(null);
    }
    if (index === 0) {
      setModalType('stocks');
    }
    if (index === 1) {
      setModalType('bonds');
    }
  };

  const handleSubmit = () => {
    if (selectedAssets[0].name in STOCKS_DATA) {
      handleSelectAnswer(0);
    } else {
      handleSelectAnswer(1);
    }
    setModalType(null);
  };

  const intro = useMemo(
    () =>
      lastAnswer !== 1 ? (
        <>
          В октябре 2018 года платформа Кэшбери закрылась, ее признали
          финансовой пирамидой. Хорошо, что не вложился(ась). На работе повысили
          зарплату, удается немного откладывать. За год накопилось{' '}
          {formatAmount(startAmount)} рублей.
        </>
      ) : (
        <>
          В октябре 2018 года платформа Кэшбери закрылась, ее признали
          финансовой пирамидой. Ты потерял(а) свои деньги, но приобрел(а) опыт.
          На работе повысили зарплату, удается немного откладывать. За год
          накопилось {formatAmount(startAmount)} рублей.
        </>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <StoryPart
        selectedAnswer={answerIndex}
        partNumber={partIndex + 1}
        partDate={date}
        onClickAnswer={handleClickAnswer}
        onClickButton={handleClickButton}
        intro={intro}
        question={
          <>
            Не хочется эти деньги потратить впустую,
            <br /> надо подумать, куда их вложить.
          </>
        }
        answers={[
          {
            title: 'Куплю акции.',
            link: VIDEO_LINKS.stocks,
          },
          {
            title: 'Куплю облигации.',
            link: VIDEO_LINKS.bonds,
          },
        ]}
      />
      <AssetsModal
        remainingBalance={remainingBalance}
        setRemainingBalance={setRemainingBalance}
        selectedAssets={selectedAssets}
        setSelectedAssets={setSelectedAssets}
        assetType={modalType}
        currentRates={rates}
        onRequestClose={handleCloseModal}
        onSubmit={handleSubmit}
      />
    </>
  );
};
