import React, { useContext } from 'react';
import { StoryPart } from '#components';
import { GameContext } from '#pages/game';
import { UseGameReturnType } from '#hooks';
import { formatAmount } from '#utils';

export const Part2: React.FC = () => {
  const {
    partIndex,
    answerIndex,
    handleSelectAnswer,
    gotoNext,
    partData: {
      cur: {
        startAmount,
        date,
        rates: { usd: usdRate },
      },
    },
  } = useContext(GameContext) as UseGameReturnType;

  const handleClickButton = () => {
    gotoNext(state => {
      if (answerIndex === 0) {
        return {
          ...state,
          expenses: state.expenses + startAmount,
          assets: {
            ...state.assets,
            deposits: {
              ...state.assets.deposits,
              twoYears: startAmount,
            },
          },
        };
      }
      if (answerIndex === 1) {
        return {
          ...state,
          expenses: state.expenses + startAmount,
          assets: {
            ...state.assets,
            pairs: [
              ...state.assets.pairs,
              {
                name: 'usd',
                amount: startAmount / usdRate,
                change: 0,
              },
            ],
          },
        };
      }
      return state;
    });
  };
  return (
    <StoryPart
      selectedAnswer={answerIndex}
      partNumber={partIndex + 1}
      partDate={date}
      onClickAnswer={handleSelectAnswer}
      onClickButton={handleClickButton}
      intro={
        <>
          В университете весь год было очень удачное расписание, поэтому было
          время на подработку.
          <br />
          За год удалось накопить {formatAmount(startAmount)} рублей. Эти деньги
          ты твердо решил(а) отложить.
        </>
      }
      question={<>Как ты распорядишься этими деньгами?</>}
      answers={[
        {
          title: 'Положу на вклад на 2 года под 8%',
        },
        {
          title: `Куплю на все деньги доллары по ${usdRate} руб`,
        },
      ]}
    />
  );
};
