import React, { useContext } from 'react';
import { StoryPart } from '#components';
import { GameContext } from '#pages/game';
import { UseGameReturnType } from '#hooks';

export const Part10: React.FC = () => {
  const {
    partIndex,
    answerIndex,
    handleSelectAnswer,
    gotoNext,
    partData: {
      cur: { date },
    },
  } = useContext(GameContext) as UseGameReturnType;

  const handleClickButton = () => {
    gotoNext(state => {
      if (answerIndex === 0) {
        const savingsAmount = state.assets.deposits.savings;
        return {
          ...state,
          cash: state.cash + savingsAmount,
          assets: {
            ...state.assets,
            deposits: {
              ...state.assets.deposits,
              savings: 0,
            },
          },
        };
      }
      return state;
    });
  };

  return (
    <StoryPart
      selectedAnswer={answerIndex}
      partNumber={partIndex + 1}
      partDate={date}
      onClickAnswer={handleSelectAnswer}
      onClickButton={handleClickButton}
      intro={
        <>
          Вот так новости! На фоне происходящих в стране и мире событий
          Центральный банк повысил ключевую ставку до 20%! Цены
          на российские ценные бумаги резко упали. Московская биржа остановила
          торги. Курс доллара взлетел до 109 рублей. Вокруг паника. Все бегут
          снимать деньги. У банкоматов очереди.
        </>
      }
      question={<>Страшно, не понятно, что делать.</>}
      answers={[
        {
          title:
            'Побегу снимать оставшиеся деньги, пусть лежат рядом дома, я буду спокойнее.',
        },
        {
          title:
            'Свободные деньги буду откладывать на накопительный счет, а когда биржа откроется, куплю облигации и дешевые акции.',
        },
      ]}
    />
  );
};
