import React from 'react';
import youthLogo from '#assets/youth.png';
import { NavLink } from 'react-router-dom';

export const Footer: React.FC = () => {
  return (
    <div className="flex flex-col py-[40px] gap-[20px]">
      <div className="flex xl:flex-row flex-col-reverse justify-center items-center  gap-[8px] text-gray-500">
        <span className="text-center">
          Игра создана при поддержке Федерального агентства по делам молодежи
          «Росмолодежь»
        </span>
        <img src={youthLogo} alt="logo" />
      </div>
      <div className="text-sm text-center text-gray-400">
        <span>Разработано </span>
        <NavLink className="underline" to="https://edisoncorp.ru">
          Edisoncorp
        </NavLink>
        .
      </div>
    </div>
  );
};
