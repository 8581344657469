import { GameState } from '#types/GameState';

export const cloneState = (state: GameState) => ({
  ...state,
  answered: [...state.answered],
  assets: {
    ...state.assets,
    pairs: state.assets.pairs.map(pair => ({ ...pair })),
    deposits: { ...state.assets.deposits },
    profits: { ...state.assets.profits },
  },
  liabilities: state.liabilities.map(liability => ({ ...liability })),
});
