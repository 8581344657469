import React, { createContext, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BackgroundContext,
  BackgroundContextReturnType,
  useGame,
  UseGameReturnType,
} from '#hooks';
import { ArrowLeftSVG, CapitalSVG, StorySVG } from '#assets/svg';
import {
  Button,
  CapitalLiabilities,
  CapitalProfitability,
  CurrentPart,
  CapitalAssets,
} from '#components';
import { msToTime } from '#utils/msToTime';
import ym from 'react-yandex-metrika';

export const GameContext = createContext<UseGameReturnType | null>(null);

export const Game: React.FC = () => {
  const { setBackground } = useContext(
    BackgroundContext,
  ) as BackgroundContextReturnType;

  const navigate = useNavigate();
  const game = useGame();

  const isBegin = game.state.partIndex === 0;
  const isEnd = game.state.partIndex === 10;

  useEffect(() => {
    if (isBegin) {
      ym('reachGoal', 'begin');
    }
  }, [isBegin]);

  useEffect(() => {
    if (isEnd) {
      ym('reachGoal', 'end');
      setBackground('transparent');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnd]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [game.state.partIndex]);

  return (
    <GameContext.Provider value={game}>
      {!isEnd ? (
        <div className="flex flex-col w-full min-h-screen h-full items-start xl:px-[35px] xl:pb-[90px] pb-[40px] pt-[22.5px] gap-[20px]">
          <Button
            className="mt-0 max-w-fit xl:min-w-fit px-[20px] py-[12px] !rounded-full"
            onClick={() => navigate('/')}
          >
            <ArrowLeftSVG />
          </Button>
          <div className="flex xl:flex-row flex-col w-full min-h-fit h-full flex-grow xl:gap-[25px] gap-[90px]">
            <div className="flex flex-grow relative w-full rounded-xl pt-[65px] pb-[12px] bg-white story-background">
              <StorySVG className="absolute left-[50%] ml-[-65px] top-[-45px]" />
              <CurrentPart partIndex={game.state.partIndex} />
              <div className="cursor-default absolute w-[100px] h-[60px] flex items-center font-['ChivoMono_Regular'] justify-center -bottom-[30px] left-0 right-0 m-auto border-2 border-afina-purple bg-afina-purple rounded-2xl text-2xl text-white overflow-hidden">
                <div className="absolute -left-[15px] blur-md bg-white/30 rounded-full w-[40px] aspect-square" />
                <span>{msToTime(game.count)}</span>
              </div>
            </div>
            <div className="relative xl:min-w-[420px] rounded-xl pt-[65px] pb-[12px] bg-white">
              <CapitalSVG className="absolute left-[50%] ml-[-45px] top-[-45px]" />
              <h3 className="text-center">КАПИТАЛ</h3>
              <div className="xl:px-[30px] px-[20px] w-full overflow-y-scroll scrollbar pb-[10px]">
                <CapitalAssets />
                <CapitalLiabilities />
                <CapitalProfitability
                  assetsTotal={game.assetsTotalRubAmount}
                  expenses={game.state.expenses}
                  profitability={game.totalProfitability}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <CurrentPart partIndex={game.state.partIndex} />
      )}
    </GameContext.Provider>
  );
};
