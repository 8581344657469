import { Button, TextContentWrapper, YouTube } from '#components';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES_ENUM } from '#components/Router';
import { VIDEO_LIST } from '#constants';

export const Knowledge: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(ROUTES_ENUM.GAME);
  };
  return (
    <TextContentWrapper>
      <h1>База знаний</h1>
      <p>
        В этом разделе вы можете ознакомиться с уроками по инструментам,
        предложенным в игре.
      </p>
      <div className="flex xl:justify-start justify-center flex-wrap h-full w-full gap-x-[80px] gap-y-[60px] xl:my-[20px]">
        {VIDEO_LIST.map(({ title, videoId }, i) => (
          <div className="flex xl:w-auto w-full flex-col gap-[20px]" key={title}>
            <h3>{`${i + 1}. ${title}`}</h3>
            <YouTube
              className="bg-afina-purple/50 rounded-xl w-full aspect-video"
              videoId={videoId}
            />
          </div>
        ))}
      </div>
      <Button className='xl:mt-0 mt-[25px]' onClick={handleClick}>Начать игру</Button>
    </TextContentWrapper>
  );
};
