import { Part1 } from '#components/Story/Part1';
import { Part2 } from '#components/Story/Part2';
import { Part3 } from '#components/Story/Part3';
import { Part4 } from '#components/Story/Part4';
import { Part5 } from '#components/Story/Part5';
import { Part6 } from '#components/Story/Part6';
import { Part7 } from '#components/Story/Part7';
import { Part8 } from '#components/Story/Part8';
import { Part9 } from '#components/Story/Part9';
import { Part10 } from '#components/Story/Part10';
import { Part11 } from '#components/Story/Part11';

export const Story = {
  Part1,
  Part2,
  Part3,
  Part4,
  Part5,
  Part6,
  Part7,
  Part8,
  Part9,
  Part10,
  Part11,
};
