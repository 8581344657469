import React, { useEffect, useState } from 'react';
import { StarSVG } from '#assets/svg';

interface RatingProps {
  onChange?: (rating: number) => void;
}

export const Rating: React.FC<RatingProps> = ({ onChange }) => {
  const [rating, setRating] = useState<number>(0);
  useEffect(() => {
    onChange?.(rating);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rating]);

  return (
    <div className="flex gap-[22px]">
      {[1, 2, 3, 4, 5].map(s => (
        <StarSVG
          key={s}
          fill={s <= rating ? '#b359ae' : 'none'}
          onClick={() => setRating(s)}
          className="cursor-pointer"
        />
      ))}
    </div>
  );
};
